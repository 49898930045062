// src/components/AdminDashboard.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import styles from './css/AdminDashboard.module.css';
import {
  FaSignOutAlt,
  FaUserPlus,
  FaRegFileAlt,
  FaCogs,
  FaListAlt,
  FaPlus,
  FaDatabase,
  FaCreditCard,
  FaUserTag,
  FaTrashAlt,
  FaSpinner,
  FaImage,
  FaCloud,
  FaExclamationTriangle,
  FaInfoCircle,
  FaUserFriends,
  FaClock,
  FaFileAlt,
  FaFont,
  FaLock,
  FaCog,
  FaChartLine,
} from 'react-icons/fa';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import Register from './Register';
import Config from './Config';
import PopupAddFeed from '../popups/PopupAddFeed';
import PopupUpdateCreditBalance from '../popups/PopupUpdateCreditBalance';
import PopupAssignClient from '../popups/PopupAssignClient';
import DeleteFeedPopup from '../popups/DeleteFeedPopup';
import { debounce } from 'lodash';

const AdminDashboard = ({ token }) => {
  const navigate = useNavigate();

  // Tabs State: 'configuration' or 'monitoring'
  const [activeTab, setActiveTab] = useState('configuration'); // Default to 'configuration'

  // Function to calculate the default start date (1 month back)
  const getDefaultStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);

    // Handle cases where subtracting a month results in an invalid date
    if (date.getDate() !== new Date().getDate()) {
      date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Separate state for User Configuration search
  const [userSearchTerm, setUserSearchTerm] = useState('');

  // Separate states for Monitoring filters and Cloud Costs filters
  const [monitoringFilters, setMonitoringFilters] = useState({
    startDate: getDefaultStartDate(),
    endDate: '',
    username: null,
    clientName: null,
  });

  const [cloudCostFilters, setCloudCostFilters] = useState({
    startDate: getDefaultStartDate(),
    endDate: '',
  });

  const [users, setUsers] = useState([]);
  const [clientStats, setClientStats] = useState([]);
  const [linkedClients, setLinkedClients] = useState([]);
  const [cloudCostTimeSeries, setCloudCostTimeSeries] = useState([]);

  const [feeds, setFeeds] = useState([]); // State for feeds
  const [feedSearchTerm, setFeedSearchTerm] = useState(''); // State for feed search
  const [filteredFeeds, setFilteredFeeds] = useState([]); // State for filtered feeds
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isPopupAddFeedOpen, setIsPopupAddFeedOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [message, setMessage] = useState('');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFeedForEdit, setSelectedFeedForEdit] = useState(null);

  // State for the credit balance popup
  const [isUpdateCreditPopupOpen, setIsUpdateCreditPopupOpen] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState(null);

  // State for the Assign Client popup
  const [isAssignClientPopupOpen, setIsAssignClientPopupOpen] = useState(false);
  const [selectedFeedForAssignment, setSelectedFeedForAssignment] = useState(null);

  const [isDeleteFeedPopupOpen, setIsDeleteFeedPopupOpen] = useState(false);
  const [selectedFeedForDeletion, setSelectedFeedForDeletion] = useState(null);

  // Metric Options
  const METRIC_OPTIONS = [
    { value: 'daily_cost', label: 'Costs ($)' },
    { value: 'daily_successes', label: 'Generations #' },
  ];

  const [selectedMetric, setSelectedMetric] = useState(METRIC_OPTIONS[0]); // Default to 'daily_cost'

  // **New Separate Loading States**
  const [isLoadingClientStats, setIsLoadingClientStats] = useState(true);
  const [isLoadingCloudCosts, setIsLoadingCloudCosts] = useState(true);
  const [isLoadingFeeds, setIsLoadingFeeds] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);

  // Filtered Users based on User Search Term
  const filteredUsers = users.filter((user) =>
    userSearchTerm
      ? user.username.toLowerCase().includes(userSearchTerm.toLowerCase())
      : true
  );

  // State for Cloud Costs
  const [cloudCosts, setCloudCosts] = useState(0.0);
  const [cloudCostBreakdown, setCloudCostBreakdown] = useState([]);
  const [isCloudCostModalOpen, setIsCloudCostModalOpen] = useState(false);

  const openDeleteFeedPopup = (feed) => {
    setSelectedFeedForDeletion(feed);
    setIsDeleteFeedPopupOpen(true);
  };
  
  const closeDeleteFeedPopup = () => {
    setSelectedFeedForDeletion(null);
    setIsDeleteFeedPopupOpen(false);
  };

  // Fetch Users
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoadingUsers(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/users`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUsers(response.data);
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch users');
      } finally {
        setIsLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [token]);

  // Fetch Linked Clients
  useEffect(() => {
    const fetchLinkedClients = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/clients`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const clientOptions = response.data.map((client) => ({
          value: client,
          label: client,
        }));
        setLinkedClients(clientOptions);
      } catch (error) {
        console.error('Error fetching linked clients:', error);
        setMessage('Failed to fetch linked clients');
      }
    };

    fetchLinkedClients();
  }, [token]);

  // Fetch Client Stats based on Monitoring Filters
  useEffect(() => {
    const fetchClientStats = async () => {
      setIsLoadingClientStats(true);
      try {
        const { startDate, endDate, username, clientName } = monitoringFilters;
        const params = { start_date: startDate, end_date: endDate };
        if (username) params.username = username.value;
        if (clientName) params.client_name = clientName.value;
  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/client-stats`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params,
          }
        );
        setClientStats(response.data);  // Now each item has feed/pdp breakdown
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch client stats');
      } finally {
        setIsLoadingClientStats(false);
      }
    };
  
    fetchClientStats();
  }, [token, monitoringFilters]);


  useEffect(() => {
    localStorage.removeItem('pdp_selectedColumns');
  }, []);

  // Fetch Cloud Costs based on Cloud Cost Filters (only date filters)
  useEffect(() => {
    const fetchCloudCosts = async () => {
      setIsLoadingCloudCosts(true);
      try {
        const { startDate, endDate } = cloudCostFilters;
        const params = {
          start_date: startDate,
          end_date: endDate,
        };
  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/cloud-costs`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params,
          }
        );
        setCloudCosts(response.data.total_cost);
        setCloudCostBreakdown(response.data.breakdown);
        setCloudCostTimeSeries(response.data.time_series); // Set the time series data
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch cloud costs');
      } finally {
        setIsLoadingCloudCosts(false);
      }
    };
  
    fetchCloudCosts();
  }, [token, cloudCostFilters]);

  // Fetch Feeds
  useEffect(() => {
    const fetchFeeds = async () => {
      setIsLoadingFeeds(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/client-feeds`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setFeeds(response.data);
        setFilteredFeeds(response.data); // Initialize filtered feeds
        console.log('Feeds fetched:', response.data); // Debugging
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch client feeds');
      } finally {
        setIsLoadingFeeds(false);
      }
    };

    fetchFeeds();
  }, [token]);

  // Handler for User Configuration Search with Debouncing
  const handleUserSearchChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  // Handlers for Monitoring Filters
  const handleMonitoringFilterChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setMonitoringFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption,
    }));
  };

  // Extract unique service names from time series data and filter based on total cost
  const filteredServiceKeys = useMemo(() => {
    if (cloudCostTimeSeries.length === 0) return [];

    // Initialize an object to store total costs per service
    const serviceTotals = {};

    // Iterate through each entry in the time series data
    cloudCostTimeSeries.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key === 'date') return; // Skip the date key
        if (!serviceTotals[key]) {
          serviceTotals[key] = 0;
        }
        serviceTotals[key] += entry[key];
      });
    });

    // Filter services with total cost >= 1 USD
    return Object.keys(serviceTotals).filter((key) => serviceTotals[key] >= 1);
  }, [cloudCostTimeSeries]);

  // Define a color palette
  const colors = [
    '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE',
    '#00C49F', '#FFBB28', '#FF8042', '#A28FD0', '#00C49F',
    // Add more colors if needed
  ];

  const handleMonitoringDateChange = (e) => {
    const { name, value } = e.target;
    setMonitoringFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCloudCostFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Handlers for Cloud Cost Filters
  const handleCloudCostDateChange = (e) => {
    const { name, value } = e.target;
    setCloudCostFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Handle Feed Search with Debouncing
  useEffect(() => {
    const debouncedFilter = debounce(() => {
      if (feedSearchTerm.trim() === '') {
        setFilteredFeeds(feeds);
      } else {
        const lowercasedTerm = feedSearchTerm.toLowerCase();
        const filtered = feeds.filter((feed) =>
          feed.client_name.toLowerCase().includes(lowercasedTerm)
        );
        setFilteredFeeds(filtered);
      }
    }, 300); // 300ms debounce

    debouncedFilter();

    return () => {
      debouncedFilter.cancel();
    };
  }, [feedSearchTerm, feeds]);

  // Handle Metric Change via Segmented Control
  const handleMetricChange = (metricValue) => {
    const newMetric = METRIC_OPTIONS.find(option => option.value === metricValue);
    if (newMetric) {
      setSelectedMetric(newMetric);
    }
  };

  // Function to fetch existing feed data
  const fetchFeedData = async (clientName) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-feed`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: clientName },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching feed data:', error);
      setMessage('Failed to fetch feed data');
      return null;
    }
  };

  // Handler to open the Edit Feed modal
  const openEditFeedModal = async (feed) => {
    const feedData = await fetchFeedData(feed.client_name);
    if (feedData) {
      setSelectedFeedForEdit(feedData);
      setIsEditModalOpen(true);
    }
  };

  // Handler to open the Update Credit Balance popup
  const openUpdateCreditPopup = (feed) => {
    setSelectedFeed(feed);
    setIsUpdateCreditPopupOpen(true);
  };

  // Handler to close the Update Credit Balance popup
  const closeUpdateCreditPopup = () => {
    setSelectedFeed(null);
    setIsUpdateCreditPopupOpen(false);
  };

  // Handler to open the Assign Client popup
  const openAssignClientPopup = (feed) => {
    setSelectedFeedForAssignment(feed);
    setIsAssignClientPopupOpen(true);
  };

  // Handler to close the Assign Client popup
  const closeAssignClientPopup = () => {
    setSelectedFeedForAssignment(null);
    setIsAssignClientPopupOpen(false);
  };

  // Function to refresh feeds after updating credit balance or assignments
  const refreshFeeds = async () => {
    setIsLoadingFeeds(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/client-feeds`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFeeds(response.data);
      setFilteredFeeds(response.data);
    } catch (err) {
      console.error(err);
      setMessage('Failed to refresh feeds');
    } finally {
      setIsLoadingFeeds(false);
    }
  };

  const refreshData = useCallback(async () => {
    // Optionally implement refresh for all data if needed
    await refreshFeeds();
    // Add other refresh functions if necessary
  }, [token, monitoringFilters, cloudCostFilters]);

  // Calculate Total Costs and Total Generations
  const textCostsFeed = clientStats.reduce(
    (acc, row) => acc + (row.feed_daily_cost || 0),
    0
  );
  const textCostsPDP = clientStats.reduce(
    (acc, row) => acc + (row.pdp_daily_cost || 0),
    0
  );
  const totalTextCosts = textCostsFeed + textCostsPDP;
  
  const generationsFeed = clientStats.reduce(
    (acc, row) => acc + (row.feed_daily_successes || 0),
    0
  );
  const generationsPDP = clientStats.reduce(
    (acc, row) => acc + (row.pdp_daily_successes || 0),
    0
  );
  const totalGenerationsCombined = generationsFeed + generationsPDP;

  // Determine if filters that don't apply to cloud costs are active
  const isNonCloudFilterActive = monitoringFilters.username !== null || monitoringFilters.clientName !== null;

  // Handle Logout
  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate('/login');
    } catch (err) {
      console.error('Failed to logout', err);
      setMessage('Failed to logout');
    }
  };

  // Handler to open Config modal
  const openConfigModal = (username) => {
    setSelectedUsername(username);
    setIsConfigModalOpen(true);
  };

  return (
    <div className={styles.adminDashboardContainer}>
      {/* Top Bar */}
      <div className={styles.topBar}>
        <button
          onClick={handleLogout}
          className={styles.logoutBtn}
          title="Logout"
          aria-label="Logout"
        >
          <FaSignOutAlt size={20} />
        </button>

        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>
            Quantum Feed Engine - Admin Dashboard
          </span>
          <span className={styles.headerSubtitle}>
            Manage Users and Clients
          </span>
        </div>

        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header Image"
          className={styles.headerImage}
        />
      </div>

      {message && <p className={`${styles.message} ${styles.messageError}`}>{message}</p>}

      <div className={styles.dashboardContent}>
      {/* Quick Links Section */}
      <div className={styles.adminTools}>
        <h2>Quick Links</h2>
        <div className={styles.toolsContainer}>
          <button
            onClick={() => navigate('/text-generation')}
            className={styles.adminToolsBtn}
            title="Text Generation Page"
            aria-label="Text Generation Page"
          >
            <FaRegFileAlt size={20} style={{ marginRight: '8px' }} />
            Feed Text Generation
          </button>
          <button
            onClick={() => navigate('/pdp-generation')}
            className={styles.adminToolsBtn}
            title="PDP Generation Page"
            aria-label="PDP Generation Page"
          >
            <FaFileAlt size={20} style={{ marginRight: '8px' }} />
            PDP Text Generation
          </button>
          <button
            onClick={() => navigate('/image-generation')}
            className={styles.adminToolsBtn}
            title="Image Generation Page"
            aria-label="Image Generation Page"
          >
            <FaImage size={20} style={{ marginRight: '8px' }} />
            Image Generation
          </button>
          <button
            onClick={() => navigate('/custom-attributes')}
            className={styles.adminToolsBtn}
            title="Custom Attribute Page"
            aria-label="Custom Attribute Page"
          >
            <FaCogs size={20} style={{ marginRight: '8px' }} />
            Custom Attributes
          </button>
        </div>
      </div>

        {/* Tab Navigation */}
        <div className={styles.tabContainer}>
          <nav className={styles.tabNav} role="tablist" aria-label="Admin Dashboard Tabs">
            <button
              className={`${styles.tab} ${activeTab === 'configuration' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('configuration')}
              role="tab"
              aria-selected={activeTab === 'configuration'}
              aria-controls="configuration-panel"
              id="configuration-tab"
            >
              <FaCogs className={styles.tabIcon} />
              Configuration
            </button>
            <button
              className={`${styles.tab} ${activeTab === 'monitoring' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('monitoring')}
              role="tab"
              aria-selected={activeTab === 'monitoring'}
              aria-controls="monitoring-panel"
              id="monitoring-tab"
            >
              <FaChartLine className={styles.tabIcon} />
              Monitoring
            </button>
          </nav>

          {/* Tab Panels */}
          <div className={styles.tabContent}>
            {/* Configuration Tab Panel */}
            {activeTab === 'configuration' && (
              <div
                role="tabpanel"
                id="configuration-panel"
                aria-labelledby="configuration-tab"
                className={styles.tabPanel}
              >
                <div className={styles.configurationContainer}>
                  {/* User Configuration Section */}
                  <div className={styles.userConfigContainer}>
                    <div className={styles.tableHeader}>
                      <h2>User Configuration</h2>
                      <button
                        onClick={() => setIsRegisterModalOpen(true)}
                        className={styles.addUserButton}
                        title="Register New User"
                        aria-label="Register New User"
                      >
                        <FaUserPlus size={20} />
                      </button>
                    </div>
                    {/* User Configuration Search */}
                    <div className={styles.searchContainer}>
                      <input
                        type="text"
                        placeholder="Search Users"
                        value={userSearchTerm}
                        onChange={handleUserSearchChange}
                        className={styles.searchInput}
                        aria-label="Search Users"
                      />
                    </div>
                    <div className={styles.userTableContainer}>
                      {isLoadingUsers ? (
                        <div className={styles.loadingContainer}>
                          <FaSpinner className={`${styles.spinner} ${styles.loadingIcon}`} aria-label="Loading Users" />
                        </div>
                      ) : (
                        <table className={styles.userTable}>
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>User Status</th>
                              <th className={styles.actionsColumn}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map((user) => (
                              <tr key={user.username}>
                                <td>{user.username}</td>
                                <td>{user.client_status}</td>
                                <td className={styles.actionsColumn}>
                                  <button
                                    onClick={() => openConfigModal(user.username)}
                                    className={styles.configBtn}
                                    title={`Manage Linked Feeds for ${user.username}`}
                                    aria-label={`Manage Linked Feeds for ${user.username}`}
                                  >
                                    Linked Feeds
                                  </button>
                                </td>
                              </tr>
                            ))}
                            {filteredUsers.length === 0 && (
                              <tr>
                                <td colSpan="3" className={styles.noData}>
                                  No users found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>

                  {/* Feed Configuration Container */}
                  <div className={styles.feedConfigContainer}>
                    <div className={styles.tableHeader}>
                      <h2>Feed Configuration</h2>
                      <button
                        onClick={() => setIsPopupAddFeedOpen(true)}
                        className={styles.addFeedButton}
                        title="Add New Feed"
                        aria-label="Add New Feed"
                      >
                        <div className={styles.iconStack}>
                          <FaListAlt size={24} />
                          <FaPlus size={12} className={styles.plusIcon} />
                        </div>
                      </button>
                    </div>

                    {/* Search Bar for Feeds */}
                    <div className={styles.searchContainer}>
                      <input
                        type="text"
                        placeholder="Search Feeds"
                        value={feedSearchTerm}
                        onChange={(e) => setFeedSearchTerm(e.target.value)}
                        className={styles.searchInput}
                        aria-label="Search Feeds"
                      />
                    </div>

                    {/* Feed Table */}
                    <div className={styles.userTableContainer}>
                      {isLoadingFeeds ? (
                        <div className={styles.loadingContainer}>
                          <FaSpinner className={`${styles.spinner} ${styles.loadingIcon}`} aria-label="Loading Feeds" />
                        </div>
                      ) : (
                        <table className={styles.userTable}>
                          <thead>
                            <tr>
                              <th>Client/feed</th>
                              <th>Credits</th>
                              <th>Creator</th>
                              <th>Type</th>
                              <th>Input</th>
                              <th className={styles.actionsColumn}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredFeeds.map((feed) => {
                              console.log(`Rendering feed: ${feed.id} - ${feed.client_name}`); // Debugging
                              return (
                                <tr key={feed.id}> {/* Use the unique 'id' */}
                                  <td>{feed.client_name}</td>
                                  <td>{feed.credit_balance}</td>
                                  <td>{feed.created_by}</td>
                                  <td>{feed.data_type}</td>
                                  <td>{feed.file_or_link_provided}</td>
                                  <td className={styles.actionsColumn}>
                                    <div className={styles.actionButtons}>
                                      {/* 1. Credits Button */}
                                      <button
                                        className={`${styles.actionButton} ${styles.creditsBtn}`}
                                        title={`Update Credit Balance for ${feed.client_name}`}
                                        aria-label={`Update Credit Balance for ${feed.client_name}`}
                                        onClick={() => openUpdateCreditPopup(feed)}
                                      >
                                        <FaCreditCard />
                                      </button>

                                      {/* 2. Assign User Button */}
                                      <button
                                        className={`${styles.actionButton} ${styles.assignBtn}`}
                                        title={`Assign Users to ${feed.client_name}`}
                                        aria-label={`Assign Users to ${feed.client_name}`}
                                        onClick={() => openAssignClientPopup(feed)}
                                      >
                                        <FaUserTag />
                                      </button>

                                      {/* 3. Data Link Button */}
                                      {feed.data_link ? (
                                        <a
                                          href={feed.data_link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className={`${styles.actionButton} ${styles.linkBtn}`}
                                          title={`View Data Link for ${feed.client_name}`}
                                          aria-label={`View Data Link for ${feed.client_name}`}
                                        >
                                          <FaDatabase />
                                        </a>
                                      ) : (
                                        <button
                                          className={`${styles.actionButton} ${styles.linkBtn}`}
                                          disabled
                                          title={`No Data Link Available for ${feed.client_name}`}
                                          aria-label={`No Data Link Available for ${feed.client_name}`}
                                        >
                                          <FaDatabase />
                                        </button>
                                      )}

                                      {/* 4. Modify/Settings Button */}
                                      <button
                                        className={`${styles.actionButton} ${styles.modifyBtn}`}
                                        title={`Modify Settings for ${feed.client_name}`}
                                        aria-label={`Modify Settings for ${feed.client_name}`}
                                        onClick={() => openEditFeedModal(feed)}
                                      >
                                        <FaCog />
                                      </button>

                                      {/* 5. Delete Button */}
                                      <button
                                        className={`${styles.actionButton} ${styles.deleteBtn}`}
                                        title={`Delete ${feed.client_name}`}
                                        aria-label={`Delete ${feed.client_name}`}
                                        onClick={() => openDeleteFeedPopup(feed)}
                                      >
                                        <FaTrashAlt />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            {filteredFeeds.length === 0 && (
                              <tr>
                                <td colSpan="6" className={styles.noData}>
                                  No feeds found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Monitoring Tab Panel */}
            {activeTab === 'monitoring' && (
              <div
                role="tabpanel"
                id="monitoring-panel"
                aria-labelledby="monitoring-tab"
                className={styles.tabPanel}
              >
                {/* Monitoring Section */}
                <div className={styles.monitoringSection}>
                  <h2>Monitoring</h2>

                  {/* Optimized Filters */}
                  <div className={styles.optimizedFiltersContainer}>
                    <div className={styles.filtersGrid}>
                      {/* Date Range Filter */}
                      <div className={styles.filterGroup}>
                        <label htmlFor="monitoringDateRange" className={styles.filterLabel}>
                          <FaClock className={styles.filterIcon} /> Date Range:
                        </label>
                        <div className={styles.dateRangeContainer}>
                          <input
                            type="date"
                            id="monitoringStartDate"
                            name="startDate"
                            value={monitoringFilters.startDate}
                            onChange={handleMonitoringDateChange}
                            className={styles.dateInput}
                            aria-label="Start Date for Monitoring"
                          />
                          <span className={styles.toText}>to</span>
                          <input
                            type="date"
                            id="monitoringEndDate"
                            name="endDate"
                            value={monitoringFilters.endDate}
                            onChange={handleMonitoringDateChange}
                            className={styles.dateInput}
                            aria-label="End Date for Monitoring"
                          />
                        </div>
                      </div>

                      {/* Client Name Dropdown */}
                      <div className={styles.filterGroup}>
                        <label htmlFor="clientName" className={styles.filterLabel}>
                          <FaUserFriends className={styles.filterIcon} /> Client Name:
                        </label>
                        <Select
                          id="clientName"
                          name="clientName"
                          options={linkedClients}
                          value={monitoringFilters.clientName}
                          onChange={handleMonitoringFilterChange}
                          className={styles.selectInput}
                          classNamePrefix="react-select"
                          isClearable
                          placeholder="Select Client"
                          aria-label="Client Name Filter"
                        />
                      </div>

                      {/* Username Dropdown */}
                      <div className={styles.filterGroup}>
                        <label htmlFor="monitoringUsername" className={styles.filterLabel}>
                          <FaUserTag className={styles.filterIcon} /> Username:
                        </label>
                        <Select
                          id="monitoringUsername"
                          name="username"
                          options={users.map((user) => ({
                            value: user.username,
                            label: user.username,
                          }))}
                          value={monitoringFilters.username}
                          onChange={handleMonitoringFilterChange}
                          className={styles.selectInput}
                          classNamePrefix="react-select"
                          isClearable
                          placeholder="Select User"
                          aria-label="Username Filter"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Costs Scorecards Container */}
                  <div className={styles.costsContainer}>
                    {/* Combined Text Costs Scorecard */}
                    <div className={styles.combinedScorecard}>
                      <div className={styles.combinedScorecardHeader}>
                        <FaFont className={styles.combinedScorecardIcon} />
                        <div className={styles.combinedScorecardContent}>
                          <div className={styles.combinedScorecardTitles}>
                            <span className={styles.combinedScorecardTitle}>Text Generation Costs</span>
                            <div className={styles.combinedScorecardSubtitles}>
                              <span>
                                Feed: $
                                {textCostsFeed.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                              <span>
                                PDP: $
                                {textCostsPDP.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </div>
                          </div>
                          <span className={styles.combinedScorecardValue}>
                            {isLoadingClientStats ? (
                              <FaSpinner
                                className={`${styles.spinner} ${styles.loadingIcon}`}
                                aria-label="Loading Text Costs"
                              />
                            ) : (
                              `$${totalTextCosts.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            )}
                          </span>
                        </div>
                      </div>
                    </div>


                    {/* Cloud Costs Scorecard */}
                    <div
                      className={`${styles.scorecard} ${styles.cloudCost}`}
                      onClick={() => setIsCloudCostModalOpen(true)}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => { if (e.key === 'Enter') setIsCloudCostModalOpen(true); }}
                      aria-label="View Cloud Cost Breakdown"
                    >
                      <FaCloud className={styles.scorecardIcon} color="#4758EB" />
                      <div className={styles.scorecardContent}>
                        <span className={styles.scorecardTitle}>Cloud Costs</span>
                        <span className={styles.scorecardValue}>
                          {isNonCloudFilterActive ? (
                            <div className={styles.cloudCostAlert} role="alert">
                              <FaExclamationTriangle className={styles.alertIcon} />
                              <span>Clear username or client name filters to view scorecard</span>
                            </div>
                          ) : isLoadingCloudCosts ? (
                            <FaSpinner className={`${styles.spinner} ${styles.loadingIcon}`} aria-label="Loading Cloud Costs" />
                          ) : (
                            `$${cloudCosts.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                          )}
                        </span>
                      </div>
                      {/* Interactive Icon */}
                      <FaInfoCircle className={styles.interactiveIcon} aria-hidden="true" />
                    </div>

                    {/* Coming Soon: Image Costs */}
                    <div className={`${styles.scorecard} ${styles.comingSoonCard}`}>
                      <FaImage className={styles.scorecardIcon} color="#E3E4ED" />
                      <div className={styles.scorecardContent}>
                        <span className={styles.scorecardTitle}>Image Generation Costs (Feed)</span>
                        <span className={styles.scorecardValue}>...</span>
                      </div>
                      <div className={styles.comingSoonBadge}>
                        <FaLock /> Coming Soon
                      </div>
                    </div>
                  </div>

                  {/* Generations Scorecards Container */}
                  <div className={styles.generationsContainer}>
                    {/* Combined Generations Scorecard */}
                      <div className={styles.combinedScorecard}>
                        <div className={styles.combinedScorecardHeader}>
                          <FaFont className={styles.combinedScorecardIcon} />
                          <div className={styles.combinedScorecardContent}>
                            <div className={styles.combinedScorecardTitles}>
                              <span className={styles.combinedScorecardTitle}>Text Generation Count</span>
                              <div className={styles.combinedScorecardSubtitles}>
                                <span>Feed: {generationsFeed.toLocaleString()}</span>
                                <span>PDP: {generationsPDP.toLocaleString()}</span>
                              </div>
                            </div>
                            <span className={styles.combinedScorecardValue}>
                              {isLoadingClientStats ? (
                                <FaSpinner
                                  className={`${styles.spinner} ${styles.loadingIcon}`}
                                  aria-label="Loading Text Generations"
                                />
                              ) : (
                                totalGenerationsCombined.toLocaleString()
                              )}
                            </span>
                          </div>
                        </div>
                      </div>


                    {/* Coming Soon: Image Generations */}
                    <div className={`${styles.scorecard} ${styles.comingSoonCard}`}>
                      <FaImage className={styles.scorecardIcon} color="#E3E4ED" />
                      <div className={styles.scorecardContent}>
                        <span className={styles.scorecardTitle}>Image Generations</span>
                        <span className={styles.scorecardValue}>...</span>
                      </div>
                      <div className={styles.comingSoonBadge}>
                        <FaLock /> Coming Soon
                      </div>
                    </div>
                  </div>

                  {/* Charts Container */}
                  <div className={styles.chartsContainer}>
                    {/* Text Generation Chart */}
                    <div className={styles.chartSection}>
                      <div className={styles.chartHeader}>
                        <h3>Text Generation</h3>
                        <div className={styles.metricToggle} role="radiogroup" aria-label="Select Metric">
                          {METRIC_OPTIONS.map((option) => (
                            <label key={option.value} className={styles.toggleLabel}>
                              <input
                                type="radio"
                                name="metric"
                                value={option.value}
                                checked={selectedMetric.value === option.value}
                                onChange={() => handleMetricChange(option.value)}
                                className={styles.toggleInput}
                              />
                              <span
                                className={`${styles.toggleButton} ${
                                  selectedMetric.value === option.value ? styles.activeToggle : ''
                                }`}
                                aria-pressed={selectedMetric.value === option.value}
                              >
                                {option.label}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>

                      {isLoadingClientStats ? (
                        <div className={styles.chartLoadingContainer}>
                          <FaSpinner
                            className={`${styles.spinner} ${styles.loadingIcon}`}
                            aria-label="Loading Text Generation Chart"
                          />
                        </div>
                      ) : (
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart
                            data={clientStats}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis
                              tickFormatter={(value) =>
                                selectedMetric.value === 'daily_cost'
                                  ? `$${Math.round(value)}`
                                  : value
                              }
                              label={{
                                value:
                                  selectedMetric.value === 'daily_cost'
                                    ? 'Costs ($)'
                                    : 'Generations (#)',
                                angle: -90,
                                position: 'insideLeft',
                                dy: 70,
                              }}
                            />
                            <Tooltip
                              formatter={(value) =>
                                selectedMetric.value === 'daily_cost'
                                  ? `$${Number(value).toFixed(4)}`
                                  : value
                              }
                            />
                            <Legend />
                            {/* Line for FEED */}
                            <Line
                              type="monotone"
                              dataKey={
                                selectedMetric.value === 'daily_cost'
                                  ? 'feed_daily_cost'
                                  : 'feed_daily_successes'
                              }
                              name={
                                selectedMetric.value === 'daily_cost'
                                  ? 'Feed Cost'
                                  : 'Feed Generations'
                              }
                              stroke="#4758EB"
                              activeDot={{ r: 8 }}
                            />
                            {/* Line for PDP */}
                            <Line
                              type="monotone"
                              dataKey={
                                selectedMetric.value === 'daily_cost'
                                  ? 'pdp_daily_cost'
                                  : 'pdp_daily_successes'
                              }
                              name={
                                selectedMetric.value === 'daily_cost'
                                  ? 'PDP Cost'
                                  : 'PDP Generations'
                              }
                              stroke="#82ca9d"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </div>


                    {/* Image Generation Chart (Coming Soon) */}
                    <div className={styles.chartSection}>
                      <h3>Image Generation</h3>
                      <div className={styles.comingSoonChart}>
                        <FaExclamationTriangle size={50} color="#FFC107" />
                        <p>Coming Soon</p>
                      </div>
                    </div>

                    {/* Cloud Cost Distribution Over Time Chart */}
                    <div className={styles.chartSection}>
                      <div className={styles.chartHeader}>
                        <h3>Cloud Cost Distribution Over Time</h3>
                      </div>
                      {/* Insert the existing Cloud Cost Distribution chart code here */}
                      {isLoadingCloudCosts ? (
                        <div className={styles.chartLoadingContainer}>
                          <FaSpinner className={`${styles.spinner} ${styles.loadingIcon}`} aria-label="Loading Cloud Cost Distribution Chart" />
                        </div>
                      ) : filteredServiceKeys.length === 0 ? (
                        <p>No services have costs exceeding $1 USD for the selected period.</p>
                      ) : (
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart
                            data={cloudCostTimeSeries}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis
                              tickFormatter={(value) => `$${Math.round(value)}`}
                              label={{
                                value: 'Costs ($)',
                                angle: -90,
                                position: 'insideLeft',
                                dy: 70,
                              }}
                            />
                            <Tooltip
                              formatter={(value, name) => [`$${Number(value).toFixed(2)}`, name]}
                            />
                            <Legend />
                            {filteredServiceKeys.map((serviceKey, index) => (
                              <Area
                                key={serviceKey}
                                type="monotone"
                                dataKey={serviceKey}
                                stackId="1"
                                stroke={colors[index % colors.length]}
                                fill={colors[index % colors.length]}
                                name={serviceKey}
                              />
                            ))}
                          </AreaChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Register Modal */}
      {isRegisterModalOpen && (
        <div
          className={styles.modalBackdrop}
          onClick={() => setIsRegisterModalOpen(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="register-modal-title"
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Register
              onClose={() => setIsRegisterModalOpen(false)}
              token={token} // Pass the token here
            />
          </div>
        </div>
      )}

      {/* Config Modal */}
      {isConfigModalOpen && (
        <div
          className={styles.modalBackdrop}
          onClick={() => setIsConfigModalOpen(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="config-modal-title"
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Config
              token={token}
              isAdmin={true} // Assuming the admin dashboard is for admins
              username={selectedUsername}
              onClose={() => setIsConfigModalOpen(false)}
            />
          </div>
        </div>
      )}

      {/* Popup Add Feed Modal for Creating New Feeds */}
      {isPopupAddFeedOpen && (
        <PopupAddFeed
          token={token}
          onClose={() => setIsPopupAddFeedOpen(false)}
          onFeedCreated={refreshData} // Pass the refresh function here
        />
      )}

      {/* Popup Add Feed Modal for Editing Existing Feeds */}
      {isEditModalOpen && selectedFeedForEdit && (
        <PopupAddFeed
          token={token}
          onClose={() => {
            setSelectedFeedForEdit(null);
            setIsEditModalOpen(false);
          }}
          onFeedCreated={refreshData}
          existingFeedData={selectedFeedForEdit} // Pass existing feed data for editing
        />
      )}

      {/* Update Credit Balance Popup */}
      {isUpdateCreditPopupOpen && selectedFeed && (
        <PopupUpdateCreditBalance
          token={token}
          feed={selectedFeed}
          onClose={closeUpdateCreditPopup}
          onUpdate={refreshFeeds} // Optional: Refresh feeds after update
        />
      )}

      {/* Assign Client Popup */}
      {isAssignClientPopupOpen && selectedFeedForAssignment && (
        <PopupAssignClient
          token={token}
          clientName={selectedFeedForAssignment.client_name}
          onClose={closeAssignClientPopup}
          onUpdate={refreshFeeds} // Refresh feeds after assignment
        />
      )}

      {/* Delete Feed Popup */}
      {isDeleteFeedPopupOpen && selectedFeedForDeletion && (
        <DeleteFeedPopup
          token={token}
          feed={selectedFeedForDeletion}
          onClose={closeDeleteFeedPopup}
          onDelete={refreshFeeds} // Refresh feeds after deletion
        />
      )}

      {/* Cloud Cost Breakdown Modal */}
      {isCloudCostModalOpen && (
        <div
          className={styles.modalBackdrop}
          onClick={() => setIsCloudCostModalOpen(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="cloud-cost-modal-title"
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalHeader}>
              <h2 id="cloud-cost-modal-title">Cloud Cost Breakdown by Service</h2>
              <button
                onClick={() => setIsCloudCostModalOpen(false)}
                className={styles.closeButton}
                aria-label="Close"
              >
                &times;
              </button>
            </div>
            <p className={styles.exchangeRate}>Exchange rate: 7 DKK = 1 USD</p>
            <div className={styles.summaryContainer}>
              <div className={styles.summaryItem}>
                <span className={styles.summaryLabel}>Total Cost:</span>
                <span className={styles.summaryValue}>
                  ${cloudCostBreakdown.reduce((acc, item) => acc + item.net_cost_after_discounts, 0).toFixed(2)}
                </span>
              </div>
              <div className={styles.summaryItem}>
                <span className={styles.summaryLabel}>Number of Services:</span>
                <span className={styles.summaryValue}>{cloudCostBreakdown.length}</span>
              </div>
            </div>
            <div className={styles.modalBody}>
              <table className={styles.userTable}>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Net Cost After Discounts ($)</th>
                    <th>Percentage of Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cloudCostBreakdown.map((item, index) => {
                    const totalCost = cloudCostBreakdown.reduce((acc, item) => acc + item.net_cost_after_discounts, 0);
                    const percentage = totalCost === 0 ? '0.00' : ((item.net_cost_after_discounts / totalCost) * 100).toFixed(2);
                    return (
                      <tr key={index}>
                        <td>{item.service}</td>
                        <td>${item.net_cost_after_discounts.toFixed(2)}</td>
                        <td>{percentage}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
