// pdpgeneration.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  FaSyncAlt, FaPlus, FaPlay, FaArrowLeft, 
  FaSignOutAlt, FaTimes, FaChevronLeft, 
  FaChevronRight, FaDatabase, FaMagic, FaDownload, FaInfoCircle, FaPencilAlt, FaTools, FaCoins  
} from 'react-icons/fa';
import Select, { components } from 'react-select';
import ClipLoader from "react-spinners/ClipLoader";
import styles from './css/PdpGeneration.module.css';
import PopupRowDataPdp from '../popups/PopupRowDataPdp';
import fileDownload from 'js-file-download';
import PopupPdpBulkActions from '../popups/PopupPdpBulkActions';
import PopupExportDataPdp from '../popups/PopupExportDataPdp';

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;
const API_URL_SCHEDULER = process.env.REACT_APP_API_URL_SCHEDULER;

const PDP_COLUMNS = [
  'meta_title', 'meta_description', 'h1', 'h2', 'intro',
  'description', 'usp', 'faq',
  'custom_field_1', 'custom_field_2', 'custom_field_3',
  'custom_field_4', 'custom_field_5', 'custom_field_6',
  'custom_field_7', 'custom_field_8', 'custom_field_9',
  'custom_field_10'
];

const PdpGeneration = ({ isAdmin, token, selectedClient, setSelectedClient }) => {
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const [linkedClients, setLinkedClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  const [filters, setFilters] = useState([]); 
  const [filterInputs, setFilterInputs] = useState([
    { column: '', type: 'contains', value: '', logic: 'AND' }
  ]);
  
  const [selectedRows, setSelectedRows] = useState([]);

  const [creditBalance, setCreditBalance] = useState(null);
  

  // Sorting states
  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');

  // Refresh states
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(null);
  const [refreshError, setRefreshError] = useState(null);

  // Row Popup states
  const [isRowPopupOpen, setIsRowPopupOpen] = useState(false);
  const [rowPopupData, setRowPopupData] = useState(null);
  const [popupSaving, setPopupSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Bulk actions popup
  const [isBulkActionsPopupOpen, setIsBulkActionsPopupOpen] = useState(false);

  // Table pagination states
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState(page);
  const [pageError, setPageError] = useState('');
  const [perPage, setPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsAll, setTotalItemsAll] = useState(0);
  const [isPaginating, setIsPaginating] = useState(false);

  // Column selection states
  const [selectedColumns, setSelectedColumns] = useState(
    JSON.parse(localStorage.getItem('pdp_selectedColumns')) || []
  );
  const [unselectedColumnsRegular, setUnselectedColumnsRegular] = useState([]);
  const [unselectedColumnsPdp, setUnselectedColumnsPdp] = useState([]);
  const [selectedColumnItems, setSelectedColumnItems] = useState([]);
  const [lastClickedColumn, setLastClickedColumn] = useState(null);
  const [originalColumnOrder, setOriginalColumnOrder] = useState([]);

  // Overall load data states
  const [loadDataStatus, setLoadDataStatus] = useState(null);
  const [loadDataError, setLoadDataError] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [loadingContext, setLoadingContext] = useState(null); // 'clientChange' | 'filterApply' | 'pagination' | etc

  // Polling
  const POLLING_INTERVAL = 10000;
  const [progressData, setProgressData] = useState(null); 
  const [isProgressLoading, setIsProgressLoading] = useState(false);
  const [progressError, setProgressError] = useState(null);
  const previousStatusRef = useRef(progressData?.status);

  // ------------------------------------------------------------------
  // Navigations & top-level handlers
  // ------------------------------------------------------------------
  const handleNavigateToPromptBuilder = () => {
    navigate('/prompt-builder-pdp');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  // Bulk Actions
  const handleBulkActionsClick = () => {
    setIsBulkActionsPopupOpen(true);
  };


  const fetchCreditBalance = async (clientName) => {
    if (!clientName) {
      setCreditBalance(null);
      return;
    }
    try {
      const response = await axios.get(`${API_URL_PDP}/get-credit-balance`, {
        params: { client_name: clientName },
        headers: { Authorization: `Bearer ${token}` },
      });
      setCreditBalance(response.data.credit_balance);
    } catch (error) {
      console.error('Error fetching credit balance:', error);
      // You could show an error message or fallback to 0
      setCreditBalance(null);
    }
  };
  

  // ------------------------------------------------------------------
  // Utility helpers
  // ------------------------------------------------------------------
  const reorderPdpColumns = (columns) => {
    return PDP_COLUMNS.filter(col => columns.includes(col));
  };
  const reorderRegularColumns = (columns) => {
    return columns.slice().sort((a, b) => originalColumnOrder.indexOf(a) - originalColumnOrder.indexOf(b));
  };
  const getDisplayName = (col) => {
    if (col.startsWith('source_')) {
      return col.replace('source_', '');
    }
    return col;
  };
  const isSourceColumn = (col) => col === 'id' || col.startsWith('source_');

  // Check if something is an image URL (basic check).
  const isImageURL = (url) => {
    if (typeof url !== 'string') return false;
    return (/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i.test(url));
  };

  const formatDateTime = (dateStr) => {
    if (!dateStr) return null;
    const dt = new Date(dateStr);
    if (isNaN(dt)) return dateStr;
    const year = dt.getFullYear();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const day = String(dt.getDate()).padStart(2, '0');
    const hours = String(dt.getHours()).padStart(2, '0');
    const minutes = String(dt.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  // Cell background color logic, factoring row selection + PDP column states
  const getCellBackgroundColor = (isRowSelected, isPdpColumn, columnState) => {
    if (isRowSelected) {
      // Entire row is selected
      if (isPdpColumn) {
        return columnState ? '#c8dbcf' : '#d7d0b2';
      } else {
        return '#d1d1d1';
      }
    } else {
      // Not row-selected
      if (isPdpColumn) {
        if (columnState === true) {
          return '#E0EBE4';  
        } else if (columnState === false) {
          return '#fefbec';  
        }
      }
      return 'transparent';
    }
  };

  // ------------------------------------------------------------------
  // Row Popup
  // ------------------------------------------------------------------
  const handleOpenRowPopup = (rowData) => {
    setRowPopupData(rowData);
    setIsRowPopupOpen(true);
    setSaveSuccess(false);
  };
  const handleCloseRowPopup = () => {
    setRowPopupData(null);
    setIsRowPopupOpen(false);
  };
  const handleSaveRowChanges = async (updatedFields) => {
    if (!rowPopupData || !selectedClient) return;
    setPopupSaving(true);
    setSaveSuccess(false);

    try {
      const payload = {
        client_name: selectedClient,
        id: rowPopupData.id,
        updated_fields: updatedFields,
      };

      const response = await axios.post(
        `${API_URL_PDP}/update-pdp-row`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setSaveSuccess(true);
        // Set the loading context to 'pagination' and indicate pagination is in progress
        setLoadingContext('pagination');
        setIsPaginating(true);
        // Re-fetch data to refresh the table using the pagination loading context
        fetchInitialData(selectedClient, page, perPage, filters, sortColumn, sortOrder, 'pagination');
      } else {
        alert(`Update failed: ${response.data.message || 'Unknown error'}`);
      }      
    } catch (error) {
      console.error('Error updating row data:', error);
      alert(`Error: ${error?.response?.data?.message || 'Failed to update data.'}`);
    } finally {
      setPopupSaving(false);
    }
  };

  // ------------------------------------------------------------------
  // Client selection
  // ------------------------------------------------------------------
  useEffect(() => {
    const fetchLinkedClients = async () => {
      if (!token) return;
      setIsClientsLoading(true);
      try {
        const response = await fetch(`${API_URL_PDP}/client-data`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setLinkedClients(data.linked_clients.map(client => ({ value: client, label: client })));
          setClientError(null);
        } else {
          setClientError('Failed to load clients');
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClientError('An error occurred');
      } finally {
        setIsClientsLoading(false);
      }
    };
    fetchLinkedClients();
  }, [token]);

  const handleClientChange = (option) => {
    const clientVal = option ? option.value : '';
    setSelectedClient(clientVal);
    setPage(1);
  
    // Reset other states if desired...
    setData([]);
    setUnselectedColumnsRegular([]);
    setUnselectedColumnsPdp([]);
    setSelectedColumns([]);
    setOriginalColumnOrder([]);
    setFilterInputs([{ column: '', type: 'contains', value: '', logic: 'AND' }]);
    setFilters([]);
  
    // If a real client is selected, fetch credit balance & data
    if (clientVal) {
      fetchCreditBalance(clientVal);
      fetchInitialData(clientVal, 1, perPage, filters, sortColumn, sortOrder, 'clientChange');
    } else {
      // If user cleared the dropdown, reset to null
      setCreditBalance(null);
    }
  };
  

  // ------------------------------------------------------------------
  // Refresh
  // ------------------------------------------------------------------
  const handleRefresh = async () => {
    if (!selectedClient) {
      setRefreshStatus('error');
      setRefreshError('No client selected.');
      return;
    }
    setRefreshStatus('loading');
    setRefreshError(null);
    try {
      const response = await axios.get(`${API_URL_SCHEDULER}/refresh-pdp-data`, {
        params: { client_name: selectedClient },
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setRefreshStatus('success');
        fetchInitialData(selectedClient, 1, perPage, filters, sortColumn, sortOrder, 'refresh');
      } else {
        setRefreshStatus('error');
        setRefreshError('Failed to refresh PDP data.');
      }
    } catch (error) {
      setRefreshStatus('error');
      const errMessage = error?.response?.data?.message || 'Error refreshing PDP data.';
      setRefreshError(errMessage);
    }
  };

  // ------------------------------------------------------------------
  // Bulk & Export
  // ------------------------------------------------------------------
  const handleDownloadAllData = async () => {
    if (!selectedClient) return;
    setIsDownloading(true);
    setDownloadError(null);
    try {
      const payload = {
        client_name: selectedClient,
        filters,
        selected_columns: displayedColumns,
        order_by: sortColumn,
        order_direction: sortOrder,
      };
      const response = await axios.post(
        `${API_URL_SCHEDULER}/export-data-pdp`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        }
      );
      fileDownload(response.data, 'export.csv'); 
    } catch (error) {
      console.error('Error exporting data:', error);
      setDownloadError(error?.response?.data?.message || 'Error exporting data.');
    } finally {
      setIsDownloading(false);
    }
  };
  const handleCancelJob = async () => {
    if (!selectedClient) return;
    setIsCanceling(true);
    try {
      await axios.post(
        `${API_URL_PDP}/cancel-pdp-progress`,
        { client_name: selectedClient },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // The poller will see "cancel_run" set to true 
    } catch (error) {
      console.error('Error cancelling job:', error);
      alert(error?.response?.data?.message || 'Error cancelling job.');
      setIsCanceling(false);
    }
  };

  // ------------------------------------------------------------------
  // Data loading & poller
  // ------------------------------------------------------------------
  const fetchInitialData = async (
    client, 
    pageParam, 
    perPageParam, 
    filtersParam, 
    orderBy, 
    orderDirection, 
    context
  ) => {
    if (!client) return;

    // Set loading states
    if (context === 'pagination') {
      setLoadDataStatus('paginationLoading');
    } else {
      setLoadDataStatus('loading');
    }
    setLoadDataError(null);
    setIsDataLoading(true);

    try {
      const response = await axios.post(
        `${API_URL_PDP}/load-data`,
        {
          client_name: client,
          page: pageParam,
          per_page: perPageParam,
          filters: filtersParam,
          order_by: orderBy,
          order_direction: orderDirection
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const fetchedData = response.data.data || [];
      setData(fetchedData);
      setTotalItems(response.data.total_items);
      setTotalItemsAll(response.data.total_items_all);

      if (fetchedData.length === 0) {
        setLoadDataStatus('noData');
      } else {
        setLoadDataStatus('success');
      }

      // Only refresh column states on certain contexts
      if (['clientChange', 'refresh', 'initialLoad'].includes(context)) {
        const allColumns = fetchedData.length > 0 ? Object.keys(fetchedData[0]) : [];
        // Remove from selectedColumns anything that no longer exists
        setSelectedColumns(prevSelected =>
          prevSelected.filter(col => allColumns.includes(col))
        );
        const regularCols = allColumns.filter(c => c === 'id' || c.startsWith('source_'));
        const pdpColsPresent = PDP_COLUMNS.filter(c => allColumns.includes(c));
        
        const unselectedRegular = reorderRegularColumns(
          regularCols.filter(col => !selectedColumns.includes(col))
        );
        const unselectedPdp = reorderPdpColumns(
          pdpColsPresent.filter(col => !selectedColumns.includes(col))
        );
        setUnselectedColumnsRegular(unselectedRegular);
        setUnselectedColumnsPdp(unselectedPdp);

        if (originalColumnOrder.length === 0) {
          setOriginalColumnOrder(allColumns);
        }
      }
    } catch (error) {
      setLoadDataStatus('error');
      const errMessage = error?.response?.data?.message || 'Error loading data.';
      setLoadDataError(errMessage);
    } finally {
      setIsDataLoading(false);
      if (context === 'pagination') {
        setIsPaginating(false);
      }
      setLoadingContext(null);
    }
  };

  // Poll the PDP progress
  useEffect(() => {
    let intervalId;
    const fetchProgress = async () => {
      if (!selectedClient) {
        setProgressData(null);
        return;
      }
      setIsProgressLoading(true);
      setProgressError(null);

      try {
        const response = await axios.get(`${API_URL_PDP}/get-pdp-progress`, {
          params: { client_name: selectedClient },
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setProgressData(response.data);
          if (response.data.status !== 'running') {
            setIsCanceling(false);
          }
        } else {
          setProgressError(`Failed to load progress (status: ${response.status}).`);
        }
      } catch (error) {
        console.error('Error fetching PDP progress:', error);
        setProgressError(error?.response?.data?.message || 'Error fetching progress data.');
      } finally {
        setIsProgressLoading(false);
      }
    };

    if (selectedClient) {
      fetchProgress();
      intervalId = setInterval(fetchProgress, POLLING_INTERVAL);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [selectedClient, token]);

  // Detect status changes from 'running' -> something else
  useEffect(() => {
    const prevStatus = previousStatusRef.current;
    const currentStatus = progressData?.status;
  
    // If job was running, but now is finished, cancelled, or errored out:
    if (
      prevStatus === 'running' &&
      currentStatus &&
      currentStatus !== 'running'
    ) {
      setLoadingContext('pagination');
      setIsPaginating(true);
  
      // Re-fetch table data
      fetchInitialData(
        selectedClient,
        page,
        perPage,
        filters,
        sortColumn,
        sortOrder,
        'pagination'
      );
  
      // Also refresh the credit balance
      if (selectedClient) {
        fetchCreditBalance(selectedClient);
      }
    }
    previousStatusRef.current = currentStatus;
  }, [progressData?.status, selectedClient]);

  // If no client is selected, reset table
  useEffect(() => {
    if (!selectedClient) {
      setData([]);
      setTotalItems(0);
      setTotalItemsAll(0);
    }
  }, [selectedClient]);

  // ------------------------------------------------------------------
  // Page, PerPage, Filter, Sorting, etc.
  // ------------------------------------------------------------------
  useEffect(() => {
    // If client changes or filters, we load new data.
    // We'll treat the very first time as 'initialLoad' if no columns yet:
    if (!selectedClient) return;
    const isInitialLoad = originalColumnOrder.length === 0;

    fetchInitialData(
      selectedClient,
      page,
      perPage,
      filters,
      sortColumn,
      sortOrder,
      isInitialLoad ? 'initialLoad' : 'clientChange'
    );
    // IMPORTANT: remove perPage from here if we do manual fetch in handlePerPageChange
    // to avoid double-fetch. So let's remove "page" from the array too if you prefer 
    // manual calls for pagination. Or keep it if you want page changes to be fetched here.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, filters, sortColumn, sortOrder]); 
  // ^ We do NOT include `page` and `perPage` so we can handle them manually 
  //   in handlePageChange + handlePerPageChange.

  useEffect(() => {
    setInputPage(page);
  }, [page]);

  const handlePageChange = async (newPage) => {
    const maxPage = Math.ceil(totalItems / perPage) || 1;
    if (newPage < 1 || newPage > maxPage) {
      setInputPage(page);
      setPageError(`Please enter a page number between 1 and ${maxPage}.`);
      setTimeout(() => setPageError(''), 3000);
      return;
    }
    if (newPage === page) return; 
    setPageError('');
    setLoadingContext('pagination');
    setIsPaginating(true);
    setPage(newPage);
    // Manually fetch data:
    await fetchInitialData(
      selectedClient,
      newPage,
      perPage,
      filters,
      sortColumn,
      sortOrder,
      'pagination'
    );
  };

  // *** UPDATED handlePerPageChange: do the same approach as handlePageChange ***
  const handlePerPageChange = async (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    if (newPerPage === perPage) return;

    setLoadingContext('pagination');
    setIsPaginating(true);
    setPerPage(newPerPage);
    setPage(1);

    // Manually fetch data
    await fetchInitialData(
      selectedClient,
      1,
      newPerPage,
      filters,
      sortColumn,
      sortOrder,
      'pagination'
    );
  };

  // ------------------------------------------------------------------
  // Filters
  // ------------------------------------------------------------------
  const addFilter = () => {
    setFilterInputs([
      ...filterInputs, 
      { column: '', type: 'contains', value: '', logic: 'AND' }
    ]);
  };
  const handleRemoveFilter = (index) => {
    const newFilterInputs = filterInputs.filter((_, i) => i !== index);
    setFilterInputs(newFilterInputs);
  };
  const handleApplyFilter = () => {
    setFilters(filterInputs); 
    setPage(1); 
    setLoadingContext('filterApply');
    if (selectedClient) {
      fetchInitialData(
        selectedClient, 
        1, 
        perPage, 
        filterInputs, 
        sortColumn, 
        sortOrder, 
        'filterApply'
      );
    }
  };

  // ------------------------------------------------------------------
  // Column selection
  // ------------------------------------------------------------------
  useEffect(() => {
    localStorage.setItem('pdp_selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  const handleColumnClick = (event, column, columnList) => {
    // Shift-click logic
    if (event.shiftKey && lastClickedColumn && columnList.includes(lastClickedColumn)) {
      const start = columnList.indexOf(lastClickedColumn);
      const end = columnList.indexOf(column);
      if (start !== -1 && end !== -1) {
        const [low, high] = [Math.min(start,end), Math.max(start,end)];
        const range = columnList.slice(low, high+1);
        const currentlySelected = [...selectedColumnItems];
        range.forEach(col => {
          if (!currentlySelected.includes(col)) {
            currentlySelected.push(col);
          }
        });
        setSelectedColumnItems(currentlySelected);
      }
    } else {
      // Normal click toggles single item
      setSelectedColumnItems(prevItems =>
        prevItems.includes(column) ? prevItems.filter(item => item !== column) : [...prevItems, column]
      );
    }
    setLastClickedColumn(column);
  };

  const moveToSelected = () => {
    const allUnselected = [...unselectedColumnsRegular, ...unselectedColumnsPdp];
    const selectedFromUnselected = allUnselected.filter(col => selectedColumnItems.includes(col));

    setSelectedColumns([...selectedColumns, ...selectedFromUnselected]);
    setUnselectedColumnsRegular(unselectedColumnsRegular.filter(col => !selectedColumnItems.includes(col)));
    setUnselectedColumnsPdp(unselectedColumnsPdp.filter(col => !selectedColumnItems.includes(col)));

    setSelectedColumnItems([]);
    setLastClickedColumn(null);
  };

  const moveToUnselected = () => {
    const selectedCols = selectedColumns.filter(col => selectedColumnItems.includes(col));
    const moveBackToRegular = selectedCols.filter(col => col === 'id' || col.startsWith('source_'));
    const moveBackToPdp = selectedCols.filter(col => PDP_COLUMNS.includes(col));

    setSelectedColumns(selectedColumns.filter(col => !selectedColumnItems.includes(col)));
    setUnselectedColumnsRegular(reorderRegularColumns([...unselectedColumnsRegular, ...moveBackToRegular]));
    setUnselectedColumnsPdp(reorderPdpColumns([...unselectedColumnsPdp, ...moveBackToPdp]));

    setSelectedColumnItems([]);
    setLastClickedColumn(null);
  };

  const onDragStart = (index, event) => {
    event.dataTransfer.effectAllowed = "move";
    setDraggedColumnIndex(index);
  };
  const onDragOver = (index, event) => {
    event.preventDefault();
    if (draggedColumnIndex === null || draggedColumnIndex === index) return;
    const cols = [...selectedColumns];
    const draggedCol = cols[draggedColumnIndex];
    cols.splice(draggedColumnIndex, 1);
    cols.splice(index, 0, draggedCol);
    setDraggedColumnIndex(index);
    setSelectedColumns(cols);
  };
  const onDragEnd = () => {
    setDraggedColumnIndex(null);
  };
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);

  // The columns actually displayed in the table
  const displayedColumns = useMemo(() => {
    if (data.length === 0) return [];
    // Filter out any that no longer exist in the data
    return selectedColumns.filter(col => Object.keys(data[0]).includes(col));
  }, [data, selectedColumns]);

  // For the filter dropdown (regular + PDP columns that exist)
  const filterOptions = useMemo(() => {
    if (data.length === 0) return [];
    const allCols = Object.keys(data[0]);
    const sourceColumns = allCols.filter(c => c === 'id' || c.startsWith('source_'));
    const pdpColumns = PDP_COLUMNS.filter(c => allCols.includes(c) && !c.endsWith('_state'));
    const sortedSourceColumns = reorderRegularColumns(sourceColumns);
    const sortedPdpColumns = reorderPdpColumns(pdpColumns);
    const finalCols = [...sortedSourceColumns, ...sortedPdpColumns];
    return finalCols.map(col => ({ value: col, label: col }));
  }, [data, originalColumnOrder]);

  // ------------------------------------------------------------------
  // Row selection (per row + "Select All")
  // ------------------------------------------------------------------
  const handleRowSelect = (rowId) => {
    setSelectedRows(prevSelected => {
      if (prevSelected.includes(rowId)) {
        return prevSelected.filter(id => id !== rowId);
      } else {
        return [...prevSelected, rowId];
      }
    });
  };

  // "Select All" for the current page
  const allDisplayedRowsSelected = data.length > 0 && data.every(row => selectedRows.includes(row.id));
  const handleToggleSelectAll = () => {
    if (allDisplayedRowsSelected) {
      // Unselect all on current page
      const idsOnPage = data.map(row => row.id);
      setSelectedRows(prev => prev.filter(id => !idsOnPage.includes(id)));
    } else {
      // Select all on current page
      const idsOnPage = data.map(row => row.id);
      setSelectedRows(prev => {
        const newSelection = [...prev];
        idsOnPage.forEach(id => {
          if (!newSelection.includes(id)) {
            newSelection.push(id);
          }
        });
        return newSelection;
      });
    }
  };

  // ------------------------------------------------------------------
  // Custom react-select styling
  // ------------------------------------------------------------------
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '34px',
      height: '34px',
      borderColor: '#e0e1eb',
      boxShadow: 'none',
      '&:hover': { borderColor: '#e0e1eb' },
      padding: '0px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '34px',
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e0e1eb',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: '0 6px',
      fontSize: '12px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: '0 6px',
      ':hover': {
        backgroundColor: '#c0c0c0',
        color: '#fff',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      fontFamily: 'Lexend Deca, sans-serif',
      fontSize: '12px',
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: '#E0E1EB',
        color: '#292B3D',
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
      fontSize: '12px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      fontSize: '12px',
    }),
  };

  // For rendering column icons in dropdown
  const ColumnOption = (props) => {
    const { label, value } = props.data;
    const isSource = isSourceColumn(value);
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{getDisplayName(label)}</span>
          {isSource
            ? <FaDatabase style={{ marginLeft: '8px' }} title="Source column" />
            : <FaMagic style={{ marginLeft: '8px' }} title="Magic column" />
          }
        </div>
      </components.Option>
    );
  };
  const ColumnSingleValue = (props) => {
    const { label, value } = props.data;
    const isSource = isSourceColumn(value);
    return (
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{getDisplayName(label)}</span>
          {isSource
            ? <FaDatabase style={{ marginLeft: '8px' }} title="Source column" />
            : <FaMagic style={{ marginLeft: '8px' }} title="Magic column" />
          }
        </div>
      </components.SingleValue>
    );
  };

  // ------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------
  return (
    <div>
    {/* Top Bar */}
    <div 
      className={styles.topBar}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',  // Optional for side spacing
      }}
    >
      {/* Left side: Back & Logout */}
      <div className={styles.topBarLeft} style={{ display: 'flex', gap: '10px' }}>
        <button 
          onClick={() => navigate(isAdmin ? '/admin' : '/client')} 
          className={styles.backButton} 
          title="Back"
        >
          <FaArrowLeft size={20} />
        </button>
        <button 
          onClick={handleLogout} 
          className={styles.logoutButton} 
          title="Logout"
        >
          <FaSignOutAlt size={20} />
        </button>
      </div>

      {/* Center: Title & Subtitle */}
      <div 
        className={styles.headerTextContainer}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <span className={styles.feedViewerVersion}>Quantum Feed Engine - PDP Generation</span>
        <span className={styles.headerSubtitle}>Elevate Your PDP Game</span>
      </div>

      {/* Right side: Dropdown, Credits, Refresh, Prompt Builder */}
      <div 
        className={styles.topBarActions} 
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '18px'
        }}
      >
        {/* Client Dropdown */}
        <Select
          options={linkedClients}
          onChange={handleClientChange}
          value={linkedClients.find(client => client.value === selectedClient) || null}
          className={styles.clientDropdown}
          placeholder={isClientsLoading ? 'Loading clients...' : 'Select Client'}
          isLoading={isClientsLoading}
          isDisabled={isClientsLoading || isDataLoading || refreshStatus === 'loading'}
          styles={customStyles}
          noOptionsMessage={() => clientError || 'No clients available'}
          isClearable
        />

        {/* Credits */}
        <div 
          style={{
            display: 'flex', 
            alignItems: 'center',
            backgroundColor: '#F8F9FF',
            padding: '6px 12px',
            borderRadius: '6px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.07)',
          }}
          title="Your available credits"
        >
          {creditBalance !== null ? (
            <span 
              style={{
                display: 'flex', 
                alignItems: 'center', 
                fontFamily: 'Lexend Deca, sans-serif',
                color: '#292B3D',
                fontSize: '14px',
                fontWeight: '500'
              }}
            >
              <FaCoins style={{ marginRight: '6px', color: '#F9C74F' }} />
              {creditBalance.toLocaleString()} Credits
            </span>
          ) : (
            <span 
              style={{ 
                fontFamily: 'Lexend Deca, sans-serif',
                color: '#999',
                fontSize: '14px'
              }}
            >
              -- Credits
            </span>
          )}
        </div>

        {/* Refresh Button */}
        <button
          className={styles.refreshButton}
          title="Refresh"
          onClick={handleRefresh}
          disabled={isRefreshing}
          style={{ position: 'relative' }}
        >
          {isRefreshing ? (
            <ClipLoader size={20} color="#4758EB" />
          ) : (
            <FaSyncAlt size={20} />
          )}
        </button>

        {/* Prompt Builder */}
        <button
          className={styles.promptBuilderButton}
          title="Go to Prompt Builder"
          onClick={handleNavigateToPromptBuilder}
        >
          <FaTools size={20} />
        </button>
      </div>

      {/* Finally, the header image is placed last with marginLeft: 'auto' */}
      <img
        src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
        alt="Header"
        className={styles.headerImage}
        style={{ marginLeft: 'auto' }}
      />
    </div>



      {/* Status Container */}
      <div className={styles.statusContainer}>
        {/* Refresh */}
        {refreshStatus === 'loading' && (
          <div className={styles.infoMessage}>
            <ClipLoader size={20} color="#4758EB" style={{ marginRight: '8px' }} />
            <span>Refreshing PDP data...</span>
          </div>
        )}
        {refreshStatus === 'success' && (
          <div className={styles.successMessage}>
            PDP data refreshed successfully.
          </div>
        )}
        {refreshStatus === 'error' && (
          <div className={styles.errorMessage}>
            {refreshError || 'Error refreshing PDP data.'}
          </div>
        )}
        {/* Load Data */}
        {loadDataStatus === 'loading' && (
          <div className={styles.infoMessage}>
            <ClipLoader size={20} color="#4758EB" style={{ marginRight: '8px' }} />
            <span>Loading data, please wait...</span>
          </div>
        )}
        {loadDataStatus === 'success' && (
          <div className={styles.successMessage}>
            Data loaded successfully.
          </div>
        )}
        {loadDataStatus === 'noData' && (
          <div className={styles.warningMessage}>
            No data was found.
          </div>
        )}
        {loadDataStatus === 'error' && (
          <div className={styles.errorMessage}>
            {loadDataError || 'Error loading data.'}
          </div>
        )}
      </div>

      {/* Column Selection & Filters: only show if a client is selected */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
       && selectedClient && (
        <>
          {/* Column Selection */}
          <div className={styles.columnSection}>
            <div className={styles.listContainer}>
              {/* Regular Columns */}
              <div className={styles.scrollableListContainer}>
                <h3>Regular Columns</h3>
                <div className={styles.scrollableList}>
                  <ul>
                    {unselectedColumnsRegular.map((col) => {
                      const isSource = isSourceColumn(col);
                      return (
                        <li
                          key={col}
                          className={selectedColumnItems.includes(col) ? styles.selected : ''}
                          onClick={(e) => handleColumnClick(e, col, unselectedColumnsRegular)}
                        >
                          <span className={styles.columnName}>{getDisplayName(col)}</span>
                          {isSource
                            ? <FaDatabase className={styles.sourceIcon} title="Source column" />
                            : <FaMagic className={styles.magicIcon} title="Magic column" />
                          }
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* PDP Columns */}
              <div className={styles.scrollableListContainer}>
                <h3>PDP Columns</h3>
                <div className={styles.scrollableList}>
                  <ul>
                    {unselectedColumnsPdp.map((col) => {
                      const isSource = isSourceColumn(col);
                      return (
                        <li
                          key={col}
                          className={selectedColumnItems.includes(col) ? styles.selected : ''}
                          onClick={(e) => handleColumnClick(e, col, unselectedColumnsPdp)}
                        >
                          <span className={styles.columnName}>{getDisplayName(col)}</span>
                          {isSource
                            ? <FaDatabase className={styles.sourceIcon} title="Source column" />
                            : <FaMagic className={styles.magicIcon} title="Magic column" />
                          }
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* Move Buttons */}
              <div className={styles.buttonGroupColumnSelection}>
                <button
                  onClick={moveToSelected}
                  disabled={
                    selectedColumnItems.length === 0 ||
                    !selectedColumnItems.some(item => 
                      unselectedColumnsRegular.includes(item) || unselectedColumnsPdp.includes(item))
                  }
                  title="Add Selected Columns"
                >
                  <FaChevronRight size={20} />
                </button>
                <button
                  onClick={moveToUnselected}
                  disabled={
                    selectedColumnItems.length === 0 ||
                    !selectedColumnItems.some(item => selectedColumns.includes(item))
                  }
                  title="Remove Selected Columns"
                >
                  <FaChevronLeft size={20} />
                </button>
              </div>
              {/* Selected Columns */}
              <div className={styles.scrollableListContainer}>
                <h3>Selected Columns</h3>
                <div className={styles.scrollableList}>
                  <ul>
                    {selectedColumns.map((col, index) => {
                      const isSource = isSourceColumn(col);
                      return (
                        <li
                          key={col}
                          className={selectedColumnItems.includes(col) ? styles.selected : ''}
                          onClick={(e) => handleColumnClick(e, col, selectedColumns)}
                          draggable
                          onDragStart={(event) => onDragStart(index, event)}
                          onDragOver={(event) => onDragOver(index, event)}
                          onDrop={onDragEnd}
                          onDragEnd={onDragEnd}
                        >
                          <span className={styles.columnName}>{getDisplayName(col)}</span>
                          {isSource
                            ? <FaDatabase className={styles.sourceIcon} title="Source column" />
                            : <FaMagic className={styles.magicIcon} title="Magic column" />
                          }
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Filters */}
          <div className={styles.filterSection}>
            {filterInputs.map((filter, index) => (
              <div key={index} className={styles.filterRow}>
                {/* Logic (only enabled if index > 0) */}
                <select
                  className={styles.filterLogicSelect}
                  value={index > 0 ? filter.logic : ''}
                  onChange={(e) => {
                    if (index > 0) {
                      const newFilterInputs = [...filterInputs];
                      newFilterInputs[index].logic = e.target.value;
                      setFilterInputs(newFilterInputs);
                    }
                  }}
                  disabled={index === 0}
                >
                  {index > 0 ? (
                    <>
                      <option value="AND">AND</option>
                      <option value="OR">OR</option>
                    </>
                  ) : (
                    <option value="" hidden />
                  )}
                </select>

                <Select
                  options={filterOptions}
                  value={
                    filter.column 
                      ? { value: filter.column, label: filter.column }
                      : null
                  }
                  onChange={(selectedOption) => {
                    const newFilterInputs = [...filterInputs];
                    newFilterInputs[index].column = selectedOption ? selectedOption.value : '';
                    setFilterInputs(newFilterInputs);
                  }}
                  placeholder="Select Column"
                  className={styles.filterDropdown}
                  isDisabled={data.length === 0}
                  noOptionsMessage={() => data.length === 0 ? 'No columns available' : 'No options'}
                  components={{
                    Option: ColumnOption,
                    SingleValue: ColumnSingleValue
                  }}
                  styles={customStyles}
                />

                {/* Filter type */}
                <select
                  className={styles.filterTypeSelect}
                  value={filter.type}
                  onChange={(e) => {
                    const newFilterInputs = [...filterInputs];
                    newFilterInputs[index].type = e.target.value;
                    setFilterInputs(newFilterInputs);
                  }}
                >
                  <option value="contains">contains</option>
                  <option value="not_contains">doesn't contain</option>
                  <option value="equals">equals</option>
                  <option value="not_equals">not equals</option>
                  <option value="starts_with">starts with</option>
                  <option value="not_starts_with">doesn't start with</option>
                  <option value="ends_with">ends with</option>
                  <option value="not_ends_with">doesn't end with</option>
                  <option value="greater_than">greater than</option>
                  <option value="less_than">less than</option>
                  <option value="greater_or_equal">greater or equal</option>
                  <option value="less_or_equal">less or equal</option>
                  <option value="length_equals">length equals</option>
                  <option value="length_greater">length greater</option>
                  <option value="length_less">length less</option>
                  <option value="matches_regexp">matches regex</option>
                  <option value="not_matches_regexp">doesn't match regex</option>
                  <option value="is_blank">is blank</option>
                  <option value="is_not_blank">is not blank</option>
                  <option value="in">is in (comma/space/NL)</option>
                  <option value="not_in">not in (comma/space/NL)</option>
                </select>

                {/* Filter Value */}
                <input
                  type="text"
                  className={styles.filterInput}
                  value={filter.value}
                  disabled={['is_blank', 'is_not_blank'].includes(filter.type)}
                  onChange={(e) => {
                    const newFilterInputs = [...filterInputs];
                    newFilterInputs[index].value = e.target.value;
                    setFilterInputs(newFilterInputs);
                  }}
                />

                {/* Remove Filter */}
                <button
                  className={styles.removeFilterButton}
                  onClick={() => handleRemoveFilter(index)}
                >
                  <FaTimes />
                </button>
              </div>
            ))}

            <div className={styles.filterActionsContainer}>
              <button 
                className={styles.addFilterButton} 
                onClick={addFilter} 
                title="Add Filter"
                disabled={loadingContext === 'filterApply'}
              >
                <FaPlus size={16} />
              </button>
              <button 
                className={styles.applyFilterButton} 
                onClick={handleApplyFilter}
                disabled={loadingContext === 'filterApply'}
                title="Apply Filters"
              >
                {loadingContext === 'filterApply' ? (
                  <ClipLoader size={16} color="#4758EB" />
                ) : (
                  <FaPlay size={16} />
                )}
              </button>
            </div>
          </div>
        </>
      )}

      {/* Toolbox */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
       && selectedClient && (
        <div 
          className={styles.toolbox}
          style={{ 
            display: 'flex',
            justifyContent: 'space-between', 
            alignItems: 'center' 
          }}
        >
          <div className="button-group">
            <button 
              className="action-button"
              onClick={handleBulkActionsClick}
              disabled={!selectedClient} 
              title="Bulk Actions"
              aria-label="Bulk Actions"
            >
              Actions
            </button>
            <button 
              className="publish-button"
              onClick={() => setIsExportPopupOpen(true)}
              title="Export"
              aria-label="Export"
            >
              Export
            </button>
            <button
              className="download-button"
              onClick={handleDownloadAllData}
              disabled={isDownloading || !selectedClient}
              title="Download Data"
              aria-label="Download Data"
            >
              {isDownloading ? (
                <ClipLoader size={14} color="#ffffff" />
              ) : (
                <FaDownload />
              )}
            </button>
          </div>

          {/* Progress Info */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {progressData && progressData.status && progressData.status !== 'no_job' && (
              <div 
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  backgroundColor: '#fff',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
              >
                {/* If Running */}
                {progressData.status === 'running' && (
                  <>
                    {!progressData.cancel_run && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong style={{ color: '#4758EB' }}>PDP Generation in progress</strong>
                        <span style={{ fontSize: '0.85rem' }}>
                          Items Processed: {progressData.items_processed} / {progressData.total_items}
                          {progressData.current_batch > 0 && progressData.total_batches > 0 && (
                            <> | Batch {progressData.current_batch} of {progressData.total_batches}</>
                          )}
                        </span>
                      </div>
                    )}
                    {progressData.cancel_run && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong style={{ color: 'orange' }}>Finishing current batch...</strong>
                        <span style={{ fontSize: '0.85rem' }}>
                          Items Processed: {progressData.items_processed} / {progressData.total_items}
                          {progressData.current_batch > 0 && progressData.total_batches > 0 && (
                            <> | Batch {progressData.current_batch} of {progressData.total_batches}</>
                          )}
                        </span>
                      </div>
                    )}
                    <div style={{ width: '100px', backgroundColor: '#ccc', height: '8px', borderRadius: '4px' }}>
                      {(() => {
                        const total = progressData.total_items || 0;
                        const processed = progressData.items_processed || 0;
                        const percent = total > 0 ? (processed / total) * 100 : 0;
                        return (
                          <div
                            style={{
                              width: `${percent}%`,
                              height: '100%',
                              backgroundColor: '#4758EB',
                              borderRadius: '4px'
                            }}
                          />
                        );
                      })()}
                    </div>
                    {!progressData.cancel_run && (
                      <button
                        className={styles.cancelGenerationButton}
                        onClick={handleCancelJob}
                        title="Cancel"
                        disabled={isCanceling}
                      >
                        {isCanceling ? (
                          <ClipLoader size={5} color="#ffffff" />
                        ) : (
                          <FaTimes />
                        )}
                      </button>
                    )}
                  </>
                )}

                {/* Completed, Error, Cancelled, Timeout */}
                {(progressData.status === 'completed'
                  || progressData.status === 'error'
                  || progressData.status === 'cancelled'
                  || progressData.status === 'timeout') && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {progressData.status === 'completed' && (
                      <strong style={{ color: 'green' }}>PDP Generation completed</strong>
                    )}
                    {progressData.status === 'error' && (
                      <strong style={{ color: 'red' }}>PDP Generation error</strong>
                    )}
                    {progressData.status === 'cancelled' && (
                      <strong style={{ color: 'orange' }}>PDP Generation cancelled</strong>
                    )}
                    {progressData.status === 'timeout' && (
                      <strong style={{ color: 'orange' }}>PDP Generation timed out</strong>
                    )}
                    <span style={{ fontSize: '0.85rem' }}>
                      Items Processed: {progressData.items_processed} / {progressData.total_items}
                      {progressData.current_batch > 0 && progressData.total_batches > 0 && (
                        <> | Batch {progressData.current_batch} of {progressData.total_batches}</>
                      )}
                    </span>
                    {progressData.end_time && (
                      <span style={{ fontSize: '0.85rem', color: '#666' }}>
                        End Time: {formatDateTime(progressData.end_time)}
                      </span>
                    )}
                    {progressData.message && (
                      <span style={{ fontSize: '0.8rem', color: '#888' }}>
                        Message: {progressData.message}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Table Preview */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
       && selectedClient && data.length > 0 && (
        <div className={styles.tableContainer}>
          {/* Overlay spinner if pagination is in progress */}
          {loadingContext === 'pagination' && isDataLoading && (
            <div className={styles.tableOverlay}>
              <ClipLoader size={50} color="#4758EB" />
              <p>Loading data, please wait...</p>
            </div>
          )}

          {displayedColumns.length > 0 ? (
            <table>
              <thead>
                <tr>
                  {/* View Column */}
                  <th className={`${styles.tableHeader} ${styles.viewColumn}`}>
                    View
                  </th>

                  {/* SELECT ALL CHECKBOX in header */}
                  <th className={`${styles.tableHeader} ${styles.selectColumn}`}>
                    <input 
                      type="checkbox"
                      onChange={handleToggleSelectAll}
                      checked={allDisplayedRowsSelected}
                      // If you want "indeterminate" for partial selection, 
                      // you'd do that via ref + .indeterminate = true
                    />
                  </th>

                  {/* Render dynamic columns */}
                  {displayedColumns.map((col) => {
                    const isSource = isSourceColumn(col);
                    return (
                      <th key={col} className={styles.tableHeader}>
                        <div className={styles.headerContent}>
                          <span className={styles.headerName}>{getDisplayName(col)}</span>
                          {isSource
                            ? <FaDatabase className={styles.headerIcon} title="Source column" />
                            : <FaMagic className={styles.headerIcon} title="Magic column" />
                          }
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {data.map((row) => {
                  const rowId = row.id;
                  const isRowSelected = selectedRows.includes(rowId);
                  return (
                    <tr key={rowId}>
                      {/* View */}
                      <td className={styles.viewColumn}>
                        <button
                          className={styles.RowDataPopupButton}
                          onClick={() => handleOpenRowPopup(row)}
                          title="View Row Details"
                        >
                          <FaInfoCircle size={12} />
                        </button>
                      </td>

                      {/* Row-level checkbox */}
                      <td className={styles.selectColumn}>
                        <input
                          type="checkbox"
                          checked={isRowSelected}
                          onChange={() => handleRowSelect(rowId)}
                        />
                      </td>

                      {/* The rest of the columns */}
                      {displayedColumns.map((col) => {
                        const cellValue = row[col];
                        const isPdpColumn = PDP_COLUMNS.includes(col);
                        const stateKey = `${col}_state`;
                        const cellState = row[stateKey];
                        const cellBgColor = getCellBackgroundColor(isRowSelected, isPdpColumn, cellState);

                        return (
                          <td key={col} style={{ backgroundColor: cellBgColor }}>
                            {isImageURL(cellValue) ? (
                              <img src={cellValue} alt={col} className={styles.tableImage} />
                            ) : (
                              cellValue
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className={styles.noDataContainer}>
              <p className={styles.noDataMessage}>Select columns to preview data.</p>
            </div>
          )}
        </div>
      )}

      {/* Pagination Controls */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
       && selectedClient && data.length > 0 && (
        <div className={styles.tableControls}>
          <button 
            onClick={() => handlePageChange(page - 1)} 
            disabled={page <= 1 || isPaginating} 
            className={styles.navButton}
            title="Previous Page"
          >
            {isPaginating ? <ClipLoader size={6} color="#FFFFFF" /> : <FaChevronLeft />}
          </button>
          
          <span className={styles.pageLabel}>Page:</span>
  
          <input
            type="number"
            className={styles.pageInput}
            value={inputPage}
            onChange={(e) => {
              const newInput = e.target.value;
              if (newInput === '') {
                setInputPage('');
                return;
              }
              const newPage = parseInt(newInput, 10);
              if (!isNaN(newPage)) {
                setInputPage(newPage);
              }
            }}
            onBlur={() => {
              const newPage = parseInt(inputPage, 10);
              if (!isNaN(newPage)) {
                handlePageChange(newPage);
              } else {
                setInputPage(page);
                setPageError('Invalid page number.');
                setTimeout(() => setPageError(''), 3000);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const newPage = parseInt(inputPage, 10);
                if (!isNaN(newPage)) {
                  handlePageChange(newPage);
                } else {
                  setInputPage(page);
                  setPageError('Invalid page number.');
                  setTimeout(() => setPageError(''), 3000);
                }
              }
            }}
            disabled={isPaginating}
            min="1"
            max={Math.ceil(totalItems / perPage) || 1}
            title="Current Page"
          />

          <span className={styles.paginationTotal}>
            / {Math.ceil(totalItems / perPage) || 1} (Total: {totalItems})
          </span>
        
          <select 
            value={perPage} 
            onChange={handlePerPageChange} 
            className={styles.inputSelect}
            disabled={isPaginating}
            title="Page Size"
          >
            <option value={10}>10</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
            <option value={2000}>2000</option>
            <option value={5000}>5000</option>
            <option value={10000}>10000</option>
          </select>
        
          <button 
            onClick={() => handlePageChange(page + 1)} 
            disabled={page >= Math.ceil(totalItems / perPage) || isPaginating} 
            className={styles.navButton}
            title="Next Page"
          >
            {isPaginating ? <ClipLoader size={6} color="#FFFFFF" /> : <FaChevronRight />}
          </button>
        </div>
      )}

      {/* Row Popup */}
      {isRowPopupOpen && rowPopupData && (
        <PopupRowDataPdp
          isOpen={isRowPopupOpen}
          onClose={handleCloseRowPopup}
          rowData={rowPopupData}
          popupSaving={popupSaving}
          saveSuccess={saveSuccess}
          onSave={handleSaveRowChanges}
        />
      )}

      {/* Export Popup */}
      {isExportPopupOpen && (
        <PopupExportDataPdp
          isOpen={isExportPopupOpen}
          onClose={() => setIsExportPopupOpen(false)}
          token={token}
          clientName={selectedClient}
          // Instead of just unselected columns, give everything:
          regularColumns={unselectedColumnsRegular.concat(
            selectedColumns.filter(isSourceColumn) // or your allRegularColumns
          )}
          pdpColumns={unselectedColumnsPdp.concat(
            selectedColumns.filter(c => PDP_COLUMNS.includes(c))
          )}
          exampleValues={{
            meta_title: "Example Title",
            meta_description: "<p>Example HTML content...</p>",
          }}
        />
      )}

      {/* Bulk Actions Popup */}
      <PopupPdpBulkActions
        isOpen={isBulkActionsPopupOpen}
        onClose={() => setIsBulkActionsPopupOpen(false)}
        token={token}
        clientName={selectedClient}
        selectedCount={selectedRows.length}
        filteredCount={totalItems}
        allCount={totalItemsAll}
        filters={filters}
        pdpColumns={PDP_COLUMNS}
        selectedIds={selectedRows}
        progressData={progressData}
        isCanceling={isCanceling}
        handleCancelJob={handleCancelJob}
        onBulkActionComplete={() => {
          setLoadingContext('pagination');
          setIsPaginating(true);
          fetchInitialData(
            selectedClient,
            page,
            perPage,
            filters,
            sortColumn,
            sortOrder,
            'pagination'
          );
        }}
      />
    </div>
  );
};

export default PdpGeneration;
