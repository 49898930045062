import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {
  FaTimes,
  FaTrash,
  FaCheck,
  FaPlus,
  FaExternalLinkAlt,
  FaMagic,
  FaDatabase,
  FaExclamationTriangle,
  FaQuestionCircle // <-- Added for tooltips
} from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './css/PopupExportDataPdp.module.css';

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

const PopupExportDataPdp = ({
  isOpen,
  onClose,
  token,
  clientName,
  regularColumns = [],
  pdpColumns = [],
  exampleValues = {}
}) => {
  const [exportsList, setExportsList] = useState([]);
  const [selectedExport, setSelectedExport] = useState(null);

  const [newExportName, setNewExportName] = useState('');
  const [isCreatingExport, setIsCreatingExport] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // **1. State for Unsaved Changes**
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Export Settings
  const [exportFormat, setExportFormat] = useState('csv');
  const [xmlItemTag, setXmlItemTag] = useState('item');
  const [sanitizeHtml, setSanitizeHtml] = useState(false);
  const [includeDraft, setIncludeDraft] = useState(false);
  const [includeNoData, setIncludeNoData] = useState(false);
  const [includeNotInFeed, setIncludeNotInFeed] = useState(false);
  const [useMappings, setUseMappings] = useState(false);

  // Columns
  const [exportColumns, setExportColumns] = useState([]);
  const [draggingIndex, setDraggingIndex] = useState(null);

  // Combine columns
  const combinedAvailableColumns = [
    ...regularColumns.map(name => ({ name, isPdp: false })),
    ...pdpColumns.map(name => ({ name, isPdp: true }))
  ];
  const usedNames = exportColumns.map(c => c.sourceColumn);
  const availableColumns = combinedAvailableColumns.filter(cc => !usedNames.includes(cc.name));

  const getDisplayName = (col) => {
    if (typeof col !== 'string') return col;
    return col.startsWith('source_') ? col.substring(7) : col;
  };

  /* ----------------------------------------------------------------
     Load existing exports on open
  ------------------------------------------------------------------ */
  useEffect(() => {
    if (!isOpen || !clientName) return;
    setErrorMessage('');
    setIsCreatingExport(false);
    setNewExportName('');

    const fetchExports = async () => {
      try {
        const resp = await axios.get(`${API_URL_PDP}/list-pdp-exports`, {
          params: { client_name: clientName },
          headers: { Authorization: `Bearer ${token}` }
        });
        if (resp.status === 200 && resp.data.exports) {
          setExportsList(resp.data.exports);
        }
      } catch (err) {
        console.error('Error fetching exports:', err);
        const serverMessage = err.response?.data?.message;
        const serverError = err.response?.data?.error;
        if (serverMessage && serverError) {
          setErrorMessage(`${serverMessage}: ${serverError}`);
        } else if (serverMessage) {
          setErrorMessage(serverMessage);
        } else if (serverError) {
          setErrorMessage(serverError);
        } else {
          setErrorMessage('Failed to load exports.');
        }
      }
    };
    fetchExports();
  }, [isOpen, clientName, token]);

  /* ----------------------------------------------------------------
     Selecting an existing export => load its settings
  ------------------------------------------------------------------ */
  const handleSelectExistingExport = (expObj) => {
    setSelectedExport(expObj || null);
    if (expObj?.settings) {
      const s = expObj.settings;
      setExportFormat(s.format || 'csv');
      setXmlItemTag(s.xmlItemTag || 'item');
      setSanitizeHtml(!!s.sanitizeHtml);
      setIncludeDraft(!!s.includeDraft);
      setIncludeNoData(!!s.includeNoData);
      setUseMappings(!!s.useMappings);
      setIncludeNotInFeed(!!s.includeNotInFeed);
      setExportColumns(s.columns || []);
      setHasUnsavedChanges(false); // Reset unsaved changes
    } else {
      // Reset
      setExportFormat('csv');
      setXmlItemTag('item');
      setSanitizeHtml(false);
      setIncludeDraft(false);
      setIncludeNoData(false);
      setUseMappings(false);
      setIncludeNotInFeed(false);
      setExportColumns([]);
      setHasUnsavedChanges(false); // Reset unsaved changes
    }
  };

  /* ----------------------------------------------------------------
     React-Select for picking an export
  ------------------------------------------------------------------ */
  const exportOptions = exportsList.map(e => ({
    value: e.name, label: e.name
  }));

  const handleSelectExportOption = (option) => {
    if (!option) {
      handleSelectExistingExport(null);
      return;
    }
    const found = exportsList.find(exp => exp.name === option.value);
    handleSelectExistingExport(found);
  };

  /* ----------------------------------------------------------------
     Create new export
  ------------------------------------------------------------------ */
  const handleCreateNewExport = async () => {
    if (!newExportName.trim()) {
      setErrorMessage('Please enter a name for the new export.');
      return;
    }
    try {
      await axios.post(`${API_URL_PDP}/create-pdp-export`, {
        client_name: clientName,
        export_name: newExportName.trim()
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Refresh the list
      const resp = await axios.get(`${API_URL_PDP}/list-pdp-exports`, {
        params: { client_name: clientName },
        headers: { Authorization: `Bearer ${token}` }
      });
      setExportsList(resp.data.exports);

      // Select newly created
      const justCreated = resp.data.exports.find(e => e.name === newExportName.trim());
      handleSelectExistingExport(justCreated);

      // Reset creation state
      setNewExportName('');
      setIsCreatingExport(false);

    } catch (err) {
      console.error('Error creating new export:', err);
      const serverMessage = err.response?.data?.message;
      const serverError = err.response?.data?.error;
      if (serverMessage && serverError) {
        setErrorMessage(`${serverMessage}: ${serverError}`);
      } else if (serverMessage) {
        setErrorMessage(serverMessage);
      } else if (serverError) {
        setErrorMessage(serverError);
      } else {
        setErrorMessage('Failed to create new export.');
      }
    }
  };

  /* ----------------------------------------------------------------
     Delete current export
  ------------------------------------------------------------------ */
  const handleDeleteExport = async () => {
    if (!selectedExport) return;
    const confirmMsg = `Are you sure you want to delete the export "${selectedExport.name}"?`;
    if (!window.confirm(confirmMsg)) return;

    setIsDeleting(true);
    setErrorMessage('');
    try {
      await axios.post(`${API_URL_PDP}/delete-pdp-export`, {
        client_name: clientName,
        export_name: selectedExport.name
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      // Remove locally
      const newList = exportsList.filter(e => e.name !== selectedExport.name);
      setExportsList(newList);
      handleSelectExistingExport(null);
    } catch (err) {
      console.error('Error deleting export:', err);
      const serverMessage = err.response?.data?.message;
      const serverError = err.response?.data?.error;
      if (serverMessage && serverError) {
        setErrorMessage(`${serverMessage}: ${serverError}`);
      } else if (serverMessage) {
        setErrorMessage(serverMessage);
      } else if (serverError) {
        setErrorMessage(serverError);
      } else {
        setErrorMessage('Failed to delete export.');
      }
    } finally {
      setIsDeleting(false);
    }
  };

  /* ----------------------------------------------------------------
     Save or Publish
  ------------------------------------------------------------------ */
  const handleSave = async (shouldPublish = false) => {
    if (!selectedExport) {
      setErrorMessage('No export selected to save.');
      return;
    }
    setErrorMessage('');
    if (shouldPublish) setIsPublishing(true);
    else setIsSaving(true);

    const updatedSettings = {
      format: exportFormat,
      xmlItemTag,
      sanitizeHtml,
      includeDraft,
      includeNoData,
      useMappings,
      includeNotInFeed,
      columns: exportColumns
    };

    try {
      // 1) Save
      await axios.post(`${API_URL_PDP}/save-pdp-export`, {
        client_name: clientName,
        export_name: selectedExport.name,
        settings: updatedSettings
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // 2) If publish => call publish route
      if (shouldPublish) {
        const pubResp = await axios.post(`${API_URL_PDP}/publish-pdp-export`, {
          client_name: clientName,
          export_name: selectedExport.name,
          ...updatedSettings
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (pubResp.status === 200) {
          const { lastPublished, link } = pubResp.data;
          // Update local
          setExportsList(prev => prev.map(e => {
            if (e.name === selectedExport.name) {
              return { ...e, lastPublished, link };
            }
            return e;
          }));
          setSelectedExport(prev => {
            if (!prev) return prev;
            return { ...prev, lastPublished, link };
          });
        }
      }

      // Update local settings
      setExportsList(prev => prev.map(e => {
        if (e.name === selectedExport.name) {
          return { ...e, settings: updatedSettings };
        }
        return e;
      }));

      // Reset Unsaved Changes
      setHasUnsavedChanges(false);

    } catch (err) {
      console.error('Error saving/publishing export:', err);
      const serverMessage = err.response?.data?.message;
      const serverError = err.response?.data?.error;
      if (serverMessage && serverError) {
        setErrorMessage(`${serverMessage}: ${serverError}`);
      } else if (serverMessage) {
        setErrorMessage(serverMessage);
      } else if (serverError) {
        setErrorMessage(serverError);
      } else {
        setErrorMessage('Failed to save or publish export.');
      }
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    }
  };

  /* ----------------------------------------------------------------
     Drag-and-drop of columns
  ------------------------------------------------------------------ */
  const handleDragStart = (index) => setDraggingIndex(index);

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggingIndex === null || draggingIndex === index) return;

    setExportColumns(prev => {
      const arr = [...prev];
      const [movingItem] = arr.splice(draggingIndex, 1);
      arr.splice(index, 0, movingItem);
      return arr;
    });
    setDraggingIndex(index);
    setHasUnsavedChanges(true);
  };

  const handleDragEnd = () => setDraggingIndex(null);

  /* ----------------------------------------------------------------
     Add/Remove columns
  ------------------------------------------------------------------ */
  const handleAddColumn = (columnName) => {
    if (exportColumns.some(c => c.sourceColumn === columnName)) return;
    setExportColumns(prev => [
      ...prev,
      { sourceColumn: columnName, mappedColumn: '' }
    ]);
    setHasUnsavedChanges(true);
  };

  const handleRemoveColumn = (columnName) => {
    setExportColumns(prev => prev.filter(c => c.sourceColumn !== columnName));
    setHasUnsavedChanges(true);
  };

  /* ----------------------------------------------------------------
     Close popup on Escape key press
  ------------------------------------------------------------------ */
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        attemptClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, hasUnsavedChanges]);

  /* ----------------------------------------------------------------
     Attempt Close Function (checks unsaved changes)
  ------------------------------------------------------------------ */
  const attemptClose = () => {
    if (hasUnsavedChanges) {
      const confirmClose = window.confirm(
        "You have unsaved changes. Are you sure you want to close without saving?"
      );
      if (confirmClose) {
        setHasUnsavedChanges(false);
        onClose();
      }
    } else {
      onClose();
    }
  };

  /* ----------------------------------------------------------------
     Export Settings Change Handlers
  ------------------------------------------------------------------ */
  const handleExportFormatChange = (opt) => {
    setExportFormat(opt.value);
    setHasUnsavedChanges(true);
  };

  const handleXmlItemTagChange = (e) => {
    setXmlItemTag(e.target.value);
    setHasUnsavedChanges(true);
  };

  const handleSanitizeHtmlChange = (e) => {
    setSanitizeHtml(e.target.checked);
    setHasUnsavedChanges(true);
  };

  const handleIncludeDraftChange = (e) => {
    setIncludeDraft(e.target.checked);
    setHasUnsavedChanges(true);
  };

  const handleIncludeNoDataChange = (e) => {
    setIncludeNoData(e.target.checked);
    setHasUnsavedChanges(true);
  };

  const handleIncludeNotInFeedChange = (e) => {
    setIncludeNotInFeed(e.target.checked);
    setHasUnsavedChanges(true);
  };

  const handleUseMappingsChange = (e) => {
    setUseMappings(e.target.checked);
    setHasUnsavedChanges(true);
  };

  /* ----------------------------------------------------------------
     If not open, don't render anything
  ------------------------------------------------------------------ */
  if (!isOpen) return null;

  const formatOptions = [
    { value: 'csv', label: 'CSV' },
    { value: 'excel', label: 'Excel' },
    { value: 'json', label: 'JSON' },
    { value: 'xml', label: 'XML' }
  ];

  return (
    <div className={styles.popupOverlay} onClick={attemptClose}>
      <div
        className={styles.popupContainer}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
      >

        {/* ======== HEADER / TOP BAR ======== */}
        <div className={styles.exportsTopBar}>
          <h2 className={styles.exportConfigTitle}>Export Configuration</h2>

          {/* Fixed-width container for dropdown vs. new export name input */}
          <div className={styles.fixedWidthContainer}>
            {isCreatingExport ? (
              <input
                type="text"
                className={styles.newExportInput}
                value={newExportName}
                onChange={(e) => {
                  setNewExportName(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                placeholder="New Export Name"
                disabled={isDeleting || isSaving || isPublishing}
              />
            ) : (
              <Select
                options={exportOptions}
                value={
                  selectedExport
                    ? { value: selectedExport.name, label: selectedExport.name }
                    : null
                }
                onChange={handleSelectExportOption}
                placeholder="Select an Export"
                isDisabled={isDeleting || isSaving || isPublishing}
              />
            )}
          </div>

          {/* Action buttons: create / confirm / cancel / delete */}
          <div className={styles.exportActionsContainer}>
            {isCreatingExport ? (
              <>
                <button
                  onClick={handleCreateNewExport}
                  className={styles.confirmCreateButton}
                  disabled={isDeleting || isSaving || isPublishing}
                >
                  <FaCheck />
                </button>
                <button
                  onClick={() => {
                    // Cancel
                    setIsCreatingExport(false);
                    setNewExportName('');
                  }}
                  className={styles.cancelCreateButton}
                  disabled={isDeleting || isSaving || isPublishing}
                >
                  <FaTimes />
                </button>
              </>
            ) : (
              <button
                onClick={() => setIsCreatingExport(true)}
                className={styles.createExportButton}
                disabled={isDeleting || isSaving || isPublishing}
              >
                <FaPlus />
              </button>
            )}

            <button
              onClick={handleDeleteExport}
              className={styles.deleteExportButton}
              disabled={!selectedExport || isDeleting || isSaving || isPublishing}
            >
              {isDeleting ? <ClipLoader size={8} color="#fff" /> : <FaTrash />}
            </button>
          </div>
        </div>

        {/* ======== ERROR MESSAGE ======== */}
        {errorMessage && (
          <div className={styles.errorMessage}>
            <FaExclamationTriangle className={styles.errorIcon} />
            <span>{errorMessage}</span>
          </div>
        )}

        {/* 
          ======== CONTENT OR "NO EXPORT SELECTED" MESSAGE ========
          If no export is selected and we're not creating a new one,
          just show a friendly message. Otherwise show the config UI.
        */}
        {(!selectedExport && !isCreatingExport) ? (
          <div className={styles.noExportSelected}>
            <FaExclamationTriangle className={styles.noExportIcon} />
            <p>Select or create an export configuration to continue.</p>
          </div>
        ) : (
          <>
            {/* ======== MAIN CONTENT (Settings + Columns) ======== */}
            <div className={styles.popupContent}>

              {/* LEFT SIDE: Export Settings */}
              <div className={styles.leftSide}>
                <h4 className={styles.leftSideHeader}>Export Settings</h4>

                {/* Export Format */}
                <div style={{ marginBottom: '8px' }}>
                  <label style={{ display: 'block', marginBottom: 4 }}>Export Format:</label>
                  <div className={styles.reactSelectContainer}>
                    <Select
                      options={formatOptions}
                      value={formatOptions.find(opt => opt.value === exportFormat)}
                      onChange={handleExportFormatChange}
                      isDisabled={!selectedExport}
                    />
                  </div>
                </div>

                {/* If XML => XML Item Tag */}
                {exportFormat === 'xml' && (
                  <div>
                    <div className={styles.configLabel}>
                      <label>XML Item Tag:</label>
                      {/* Tooltip icon + text */}
                      <div className={styles.tooltipWrapper}>
                        <FaQuestionCircle className={styles.tooltipIcon} />
                        <div className={styles.tooltipText}>
                          If you export in XML format, each row becomes an XML “item” element.
                          This field lets you customize the tag name (e.g. &lt;item&gt;).
                        </div>
                      </div>
                    </div>
                    <input
                      type="text"
                      value={xmlItemTag}
                      onChange={handleXmlItemTagChange}
                      disabled={!selectedExport}
                    />
                  </div>
                )}

                {/* Sanitize HTML */}
                <div className={styles.checkboxWrapper}>
                  <input
                    type="checkbox"
                    id="sanitizeHtml"
                    checked={sanitizeHtml}
                    onChange={handleSanitizeHtmlChange}
                    disabled={!selectedExport}
                  />
                  <div className={styles.configLabel}>
                    <label htmlFor="sanitizeHtml">Sanitize HTML</label>
                    <div className={styles.tooltipWrapper}>
                      <FaQuestionCircle className={styles.tooltipIcon} />
                      <div className={styles.tooltipText}>
                        Remove HTML tags from PDP columns (e.g. &lt;p&gt;, &lt;strong&gt;).
                        Helpful if you need plain text output.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Include Draft */}
                <div className={styles.checkboxWrapper}>
                  <input
                    type="checkbox"
                    id="includeDraft"
                    checked={includeDraft}
                    onChange={handleIncludeDraftChange}
                    disabled={!selectedExport}
                  />
                  <div className={styles.configLabel}>
                    <label htmlFor="includeDraft">Include "draft" data</label>
                    <div className={styles.tooltipWrapper}>
                      <FaQuestionCircle className={styles.tooltipIcon} />
                      <div className={styles.tooltipText}>
                        Include unactivated data in your export (yellow cells).
                      </div>
                    </div>
                  </div>
                </div>

                {/* Include No Data */}
                <div className={styles.checkboxWrapper}>
                  <input
                    type="checkbox"
                    id="includeNoData"
                    checked={includeNoData}
                    onChange={handleIncludeNoDataChange}
                    disabled={!selectedExport}
                  />
                  <div className={styles.configLabel}>
                    <label htmlFor="includeNoData">Include IDs with no data</label>
                    <div className={styles.tooltipWrapper}>
                      <FaQuestionCircle className={styles.tooltipIcon} />
                      <div className={styles.tooltipText}>
                        Include ID's even though there is no data for the ID.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Include Not In Feed */}
                <div className={styles.checkboxWrapper}>
                  <input
                    type="checkbox"
                    id="includeNotInFeed"
                    checked={includeNotInFeed}
                    onChange={handleIncludeNotInFeedChange}
                    disabled={!selectedExport}
                  />
                  <div className={styles.configLabel}>
                    <label htmlFor="includeNotInFeed">Include items not in current feed</label>
                    <div className={styles.tooltipWrapper}>
                      <FaQuestionCircle className={styles.tooltipIcon} />
                      <div className={styles.tooltipText}>
                        Include data which is not in your current feed.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Use Mappings */}
                <div className={styles.checkboxWrapper}>
                  <input
                    type="checkbox"
                    id="useMappings"
                    checked={useMappings}
                    onChange={handleUseMappingsChange}
                    disabled={!selectedExport}
                  />
                  <div className={styles.configLabel}>
                    <label htmlFor="useMappings">Mappings (rename columns)</label>
                    <div className={styles.tooltipWrapper}>
                      <FaQuestionCircle className={styles.tooltipIcon} />
                      <div className={styles.tooltipText}>
                        Rename columns for the export. For example, 
                        rename "description" to "prod_description."
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* RIGHT SIDE: Columns Selection */}
              <div className={styles.rightSide}>
                <h4 className={styles.rightSideHeader}>Columns Selection</h4>

                <div className={styles.columnsContainer}>
                  <h5 style={{ margin: 0 }}>Available Columns</h5>
                  <div className={styles.columnsList}>
                    {availableColumns.length === 0 ? (
                      <div className={styles.placeholderText}>
                        All columns have been added
                      </div>
                    ) : (
                      availableColumns.map((col) => {
                        const example = exampleValues[col.name] || '';
                        return (
                          <div
                            key={col.name}
                            className={`${styles.columnItem} ${styles.columnItemWithTooltip}`}
                            onClick={() => handleAddColumn(col.name)}
                          >
                            <div className={styles.columnItemLeft}>
                              {col.isPdp
                                ? <FaMagic style={{ color: '#4758EB' }} />
                                : <FaDatabase style={{ color: '#888' }} />
                              }
                              {getDisplayName(col.name)}
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>

                <div className={styles.columnsContainer}>
                  <h5 style={{ margin: 0 }}>Export Columns</h5>
                  <div className={styles.columnsList}>
                    {exportColumns.length === 0 ? (
                      <div className={styles.placeholderText}>No columns selected</div>
                    ) : (
                      exportColumns.map((colObj, index) => {
                        const { sourceColumn, mappedColumn } = colObj;
                        const example = exampleValues[sourceColumn] || '';
                        const isPdp = pdpColumns.includes(sourceColumn);

                        return (
                          <div
                            key={sourceColumn}
                            className={`${styles.columnItem} ${styles.columnItemWithTooltip}`}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDragEnd={handleDragEnd}
                          >
                            <div className={styles.columnItemLeft}>
                              {isPdp
                                ? <FaMagic style={{ color: '#4758EB' }} />
                                : <FaDatabase style={{ color: '#888' }} />
                              }
                              {getDisplayName(sourceColumn)}

                              {useMappings && (
                                <div className={styles.mappingContainer}>
                                  <span className={styles.mappingArrow}>→</span>
                                  <input
                                    type="text"
                                    className={styles.mappingInput}
                                    value={mappedColumn}
                                    onChange={(e) => {
                                      const newVal = e.target.value;
                                      setExportColumns(prev => {
                                        const clone = [...prev];
                                        clone[index] = {
                                          sourceColumn,
                                          mappedColumn: newVal
                                        };
                                        return clone;
                                      });
                                      setHasUnsavedChanges(true);
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                  
                            <button
                              className={styles.removeColumnButton}
                              onClick={() => handleRemoveColumn(sourceColumn)}
                            >
                              <FaTimes />
                            </button>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* ======== UNSAVED CHANGES MESSAGE ======== */}
            {hasUnsavedChanges && (
              <div className={styles.unsavedChangesMessage}>
                <FaExclamationTriangle className={styles.unsavedIcon} />
                <span>You have unsaved changes.</span>
              </div>
            )}

            {/* ======== FOOTER ======== */}
            <div className={styles.popupFooter}>
              <div className={styles.footerLeft}>
                {selectedExport?.link && (
                  <a
                    href={selectedExport.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.viewButton}
                  >
                    View Export File
                  </a>
                )}
                {selectedExport?.lastPublished
                  ? <>Last Published: {selectedExport.lastPublished.slice(0, 16).replace('T', ' ')}</>
                  : <>Not published yet.</>
                }
              </div>
              <div className={styles.footerRight}>
                <button
                  className={styles.saveButton}
                  onClick={() => handleSave(false)}
                  disabled={!selectedExport || isSaving || isPublishing}
                >
                  {isSaving && !isPublishing ? <ClipLoader size={14} color="#fff" /> : 'Save'}
                </button>
                <button
                  className={styles.publishButton}
                  onClick={() => handleSave(true)}
                  disabled={!selectedExport || isSaving || isPublishing}
                >
                  {isPublishing ? <ClipLoader size={14} color="#fff" /> : 'Save & Publish'}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupExportDataPdp;
