// PopupRowDataPdp.jsx
import React, { useState, useEffect, memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FaTimes, FaDatabase, FaMagic,
  FaToggleOn, FaToggleOff
} from 'react-icons/fa';
import styles from './css/PopupRowDataPdp.module.css'; 

const PDP_COLUMNS = [
  'meta_title', 'meta_description', 'h1', 'h2', 'intro',
  'description', 'usp', 'faq',
  'custom_field_1', 'custom_field_2', 'custom_field_3',
  'custom_field_4', 'custom_field_5', 'custom_field_6',
  'custom_field_7', 'custom_field_8', 'custom_field_9',
  'custom_field_10'
];

/** 
 * Naive check: returns true if the string contains any <tag> patterns.
 * e.g. "<p>" "<div>" "<strong>" etc.
 * If so, we assume the content is "HTML" and can safely default to Rich Text mode.
 */
const isProbablyHtml = (value) => {
  if (!value || typeof value !== 'string') return false;
  // Look for any <something> content
  return /<[^>]+>/g.test(value);
};

const getDisplayName = (col) => {
  if (col.startsWith('source_')) {
    return col.replace('source_', '');
  }
  // Optionally, format other column names if needed
  return col.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

const isImageURL = (url) => {
  if (typeof url !== 'string') return false;
  return (/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i.test(url));
};

const PopupRowDataPdp = ({
  isOpen,
  onClose,
  rowData,
  popupSaving,        // Indicates if the save operation is in progress
  saveSuccess,        // Indicates if the save operation was successful
  onSave,             // Function to handle save action
}) => {
  const [editablePdpColumns, setEditablePdpColumns] = useState({});
  /**
   * editorModes holds per-column toggle:
   *  true  = Rich Text editor (ReactQuill)
   *  false = Plain text (textarea)
   */
  const [editorModes, setEditorModes] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (rowData) {
      const initialEditable = {};
      const initialModes = {};

      PDP_COLUMNS.forEach(col => {
        // Grab existing data or empty string
        const currentValue = rowData[col] || '';
        initialEditable[col] = currentValue;
        // Detect if the string is likely HTML
        initialModes[col] = isProbablyHtml(currentValue);
      });

      setEditablePdpColumns(initialEditable);
      setEditorModes(initialModes);
      setHasUnsavedChanges(false);
    }
  }, [rowData]);

  if (!isOpen || !rowData) return null;

  const pdpColumns = PDP_COLUMNS
    .filter(col => rowData.hasOwnProperty(col))
    .map(col => ({ key: col, value: editablePdpColumns[col] }));

  const regularColumns = Object.entries(rowData)
    .filter(([key]) => key.startsWith('source_'))
    .map(([key, value]) => ({ key, value }));

  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  const getSourceValue = (sourceKey, fallbackKey) => {
    return rowData[sourceKey] || rowData[fallbackKey] || 'N/A';
  };

  const getSourceImageLink = () => {
    return rowData['source_image_link'] || rowData['image_link'] || null;
  };

  const handlePdpChange = (key, value) => {
    setEditablePdpColumns(prev => ({
      ...prev,
      [key]: value,
    }));
    setHasUnsavedChanges(true);
  };

  const handleSave = () => {
    if (onSave) {
      // Only include fields that actually changed
      const updatedFields = {};
      PDP_COLUMNS.forEach(col => {
        if (editablePdpColumns[col] !== (rowData[col] || '')) {
          updatedFields[col] = editablePdpColumns[col];
        }
      });

      if (Object.keys(updatedFields).length > 0) {
        onSave(updatedFields);
        setHasUnsavedChanges(false);
      } else {
        alert('No changes detected to save.');
      }
    }
  };

  const handleAttemptClose = () => {
    if (hasUnsavedChanges) {
      const confirmClose = window.confirm(
        'You have unsaved changes. Are you sure you want to close without saving?'
      );
      if (confirmClose) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  // Toggle an individual PDP column's editor mode
  const toggleEditorMode = (key) => {
    setEditorModes(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className={styles.popupOverlay} onClick={handleAttemptClose}>
      <div className={styles.popupContentDual} onClick={handleInnerClick}>
        
        {/* Close Button */}
        <button
          className={styles.closeButton}
          onClick={handleAttemptClose}
          title="Close"
        >
          <FaTimes />
        </button>

        {/* Header Section */}
        <div className={styles.popupHeader}>
          <div className={styles.popupPreview}>
            <div className={styles.popupPreviewImage}>
              {getSourceImageLink() && isImageURL(getSourceImageLink()) ? (
                <img
                  src={getSourceImageLink()}
                  alt={getSourceValue('source_title', 'title')}
                />
              ) : (
                <div className={styles.noImage}>No Image Available</div>
              )}
            </div>
            <div className={styles.popupPreviewDetails}>
              <p><strong>ID:</strong> {rowData.id || 'N/A'}</p>
              <p><strong>Title:</strong> {getSourceValue('source_title', 'title')}</p>
              <p><strong>Brand:</strong> {getSourceValue('source_brand', 'brand')}</p>
            </div>
          </div>
        </div>

        {/* Dual Content Section */}
        <div className={styles.dualContainer}>
          
          {/* LEFT SIDE: Regular Columns */}
          <div className={styles.leftSide}>
            <h3>Source Columns</h3>
            <div className={styles.leftContent}>
              {regularColumns.length > 0 ? (
                regularColumns.map(({ key, value }) => (
                  <div className={styles.rowItem} key={key}>
                    <div className={styles.rowItemKey}>
                      {getDisplayName(key)}{' '}
                      {key.startsWith('source_') ? (
                        <FaDatabase className={styles.columnIcon} title="Source column" />
                      ) : (
                        <FaMagic className={styles.columnIcon} title="Magic column" />
                      )}
                    </div>
                    <div className={styles.rowItemValue}>
                      {isImageURL(value) ? (
                        <img
                          src={value}
                          alt={getDisplayName(key)}
                          className={styles.rowImage}
                        />
                      ) : (
                        value || 'N/A'
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Source Columns Available.</p>
              )}
            </div>
          </div>

          {/* RIGHT SIDE: PDP Columns (with per-column toggle) */}
          <div className={styles.rightSide}>
            <h3>PDP Columns</h3>
            <div className={styles.rightContent}>
              {pdpColumns.length > 0 ? (
                pdpColumns.map(({ key }) => (
                  <div className={styles.rowItem} key={key}>
                    {/* Row Header: label/icon on left, toggle on right */}
                    <div className={styles.rowItemHeader}>
                      <div className={styles.rowItemLabel}>
                        {getDisplayName(key)}{' '}
                        <FaMagic className={styles.columnIcon} title="PDP column" />
                      </div>
                      {/* A small label + toggle to indicate "Rich Text" vs "Plain Text" */}
                      <div className={styles.editorToggle}>
                        <span className={styles.editorToggleLabel}>
                          {editorModes[key] ? 'Rich Text' : 'Plain Text'}
                        </span>
                        <button
                          className={`${styles.toggleButton} ${editorModes[key] ? styles.toggleButtonActive : ''}`}
                          onClick={() => toggleEditorMode(key)}
                          title="Toggle Rich Text Editor"
                          aria-pressed={editorModes[key]}
                          role="switch"
                          aria-label={`Toggle Rich Text Editor for ${getDisplayName(key)}`}
                        >
                          {editorModes[key] ? <FaToggleOn /> : <FaToggleOff />}
                        </button>
                      </div>

                    </div>

                    {/* Row Content: Rich Text or Plain Text */}
                    <div className={styles.rowItemValue}>
                      {editorModes[key] ? (
                        <ReactQuill
                          theme="snow"
                          value={editablePdpColumns[key]}
                          onChange={(content) => handlePdpChange(key, content)}
                          modules={{
                            toolbar: [
                              [{ 'header': [1, 2, 3, false] }],
                              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                              ['link', 'image'],
                              ['clean']
                            ],
                          }}
                          formats={[
                            'header',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image'
                          ]}
                          className={styles.richTextEditor}
                        />
                      ) : (
                        <textarea
                          value={editablePdpColumns[key]}
                          onChange={(e) => handlePdpChange(key, e.target.value)}
                          className={styles.plainTextEditor}
                          rows={4}
                        />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No PDP Columns Available.</p>
              )}
            </div>
          </div>
        </div>

        {/* Optional "unsaved changes" or "save success" messages */}
        {hasUnsavedChanges && (
          <div className={styles.popupUnsaved}>
            You have unsaved changes.
          </div>
        )}
        {saveSuccess && (
          <div className={styles.popupSuccess}>
            Changes saved successfully!
          </div>
        )}

        {/* Sticky Save Button Container */}
        <div className={styles.stickySaveContainer}>
          <button
            className={styles.saveButton}
            onClick={handleSave}
            disabled={popupSaving || !hasUnsavedChanges}
            title="Save Changes"
          >
            {popupSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupRowDataPdp;
